import {DefaultDataService, DefaultDataServiceConfig, HttpUrlGenerator} from '@ngrx/data';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {Product} from '../our-product.model';
import {delay, map, take} from 'rxjs/operators';
import {DropdownGraph} from '../../dropdown/dropdown.model';

const defaultDataServiceConfig: DefaultDataServiceConfig = {
  root: environment.backend,
  timeout: 3000, // request timeout
};

@Injectable()
export class ProductDataService extends DefaultDataService<Product> {

  headers;

  _dropdownGraph$: BehaviorSubject<DropdownGraph<Product>> = new BehaviorSubject<DropdownGraph<Product>>(undefined);

  constructor(
    http: HttpClient,
    httpUrlGenerator: HttpUrlGenerator,
  ) {
    super('Product', http, httpUrlGenerator, defaultDataServiceConfig);
    // this.headers = new HttpHeaders();
    // this.headers.append('Accept', 'application/json');
    // this.headers.append('Content-Type', 'application/json');
    // this.headers.append('Access-Control-Allow-Origin', '*');
    // this.headers.append('Access-Control-Allow-Credentials', 'true');
    // this.headers.append("Access-Control-Allow-Methods", "POST, GET, OPTIONS, PUT, DELETE");
    // this.headers.append("Access-Control-Allow-Headers", "Content-Type, Content-Length, Accept-Encoding, X-CSRF-Token");
  }

  getAll(): Observable<Product[]>{
    return this.getJSON()
      .pipe(
        map(products => {
            this._dropdownGraph$.next(new DropdownGraph(products))
            return products
          }
        )
      );
  }

  getById(key: number | string): Observable<Product> {
    return this.getJSON()
      .pipe(
        map(products => products.find(item => item.id === key))
      )
  }

  public getJSON(): Observable<Product[]> {

    const headers = new HttpHeaders({
      'Cache-Control':  'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
      'Pragma': 'no-cache',
      'Expires': '0'
    });
    return this.http.get('./assets/data/products-catalogue.json', {headers: headers}) as Observable<Product[]>
  }

  get dropdownGraph$(): Observable<DropdownGraph<Product>> {
    return this._dropdownGraph$.asObservable();
  }

  hasModels(product: Product): boolean {
    if (!product) return false;
    return this._dropdownGraph$.value
      .getChildren(product)
      .some(item => {
        const castItem: Product = item as Product;
        return castItem.level === product.level+1 && castItem.productType === 'model'
      })
  }

  hasFamily(product: Product): boolean {
    if (!product) return true;
    else {
      return this._dropdownGraph$.value
        .getChildren(product)
        .some(item => {
          const castItem: Product = item as Product;
          return castItem.level === product.level+1 && (castItem.productType === 'family' || castItem.productType === 'subfamily')
        })
    }
  }
}
