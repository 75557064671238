import {AfterViewInit, Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Newsletter} from '../newsletters.model';
import {NewslettersEntityService} from '../store/newsletters-entity.service';
import {take} from 'rxjs/operators';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {OwlOptions} from 'ngx-owl-carousel-o';
import {ActivatedRoute, Router} from '@angular/router';
declare const $: any;
declare const AOS: any;

@Component({
  selector: 'app-newsletters-list',
  templateUrl: './newsletters-list.component.html',
  styleUrls: ['./newsletters-list.component.css'],
  encapsulation: ViewEncapsulation.None,

})
export class NewslettersListComponent implements OnInit, AfterViewInit, OnDestroy {

  subscriptions: Subscription[] = [];

  years: number[] = [];
  months: string[] = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];
  monthHasNewsletter: boolean[] = [];
  newsletters: Newsletter[];
  newsletter: Newsletter;
  filteredNewsletters: Newsletter[];

  activeYearFilter: BehaviorSubject<number> = new BehaviorSubject<number>(undefined);
  activeMonthFilter: BehaviorSubject<number> = new BehaviorSubject<number>(undefined);

  year: number;
  month: number;
  id: string;

  loading$: Observable<boolean>;

  monthFilterOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 2
      },
      400: {
        items: 6
      },
      740: {
        items: 9
      },
      940: {
        items: 12
      }
    },
    nav: false
  }
  yearFilterOptions: OwlOptions = JSON.parse(JSON.stringify(this.monthFilterOptions));

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private newslettersEntityService: NewslettersEntityService
  ) {
    this.loading$ = this.newslettersEntityService.loading$;
  }

  ngOnInit(): void {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out-back",
      once: true,
      offset: -99999
    });


    //Subscribes to query params changes and emits year change accordingly
    //Retrieve the year number where there are newsletters
    this.newslettersEntityService.getYears().pipe(take(1)).subscribe(next => {
          this.years = next;

          //Subscribe to query params change
          this.subscriptions.push(
            this.queryParamsSubscription()
          )

          //Subscribe to year filter change
          this.subscriptions.push(
            this.yearFilterSubscription()
          )

          //Subscribe to month filter change
          this.subscriptions.push(
            this.monthFilterSubscription()
          )
        }
      )

  }

  private queryParamsSubscription(): Subscription {
    return this.activatedRoute.queryParams.subscribe(next => {
      this.year = parseInt(next['year']);
      this.month = parseInt(next['month']);
      this.id = next['id'];

      //Verifies if the year is valid, if not assign a valid year
      if(!this.years.some(item => item === this.year)) {
        if (this.years.length > 0) {
          this.year = this.years[this.years.length-1];
        } else {
          this.year = new Date().getFullYear();
        }
      }
      //stream year change
      this.activeYearFilter.next(this.year);
    });
  }

  private yearFilterSubscription(): Subscription {
    return this.activeYearFilter
      .subscribe(year => {


        //Retrieve newsLetters for this year
        this.newslettersEntityService.getAll().pipe(take(1))
          .subscribe(next => {
            this.newsletters = next;

            if (this.newsletters.length === 0) return;

            let date: Date;
            for (let i = 0 ; i < 12; i++) {
              this.monthHasNewsletter[i] = next.some(item => {
                date = new Date(item.Date);
                return date.getMonth() === i && date.getFullYear() === year;
              });
            }

            //If the given month is not a valid value, give it a valid value
            if (isNaN(this.month) || !isFinite(this.month) || this.month < 0 || this.month > 11) {
              this.month = new Date(this.newsletters[0].Date).getMonth();
            }

            //If the current month doesnt have a newsletter, set the month to the last month with a newsletter
            if (!this.monthHasNewsletter[this.month]) {
              this.month = this.monthHasNewsletter.lastIndexOf(true);
            }
            this.activeMonthFilter.next(this.month);
          })
      })
  }

  private monthFilterSubscription(): Subscription {
    return this.activeMonthFilter
      .subscribe(next => {
        if (next != undefined) {
          //Filter up newsletters according to year and month
          this.filteredNewsletters = this.newsletters.filter(item => {
            const date = new Date(item.Date);
            return date.getMonth() === next && date.getFullYear() === this.year;
          });
        }
      })
  }


  ngAfterViewInit() {

  }

  ngOnDestroy() {
    for (let subscription of this.subscriptions){
      subscription.unsubscribe();
    }
  }

  activateYearFilter(year: number) {
    this.id = undefined
    this.router.navigateByUrl(this.getNavigateQuery(year, this.month, this.id));
  }

  activateMonthFilter(month: number) {
    this.id = undefined
    this.router.navigateByUrl(this.getNavigateQuery(this.year, month, this.id));
  }


  getDateString(date: number) {
    return new Date(date).toLocaleDateString();
  }

  private recalculateFilters() {

  }

  private getNavigateQuery(year: number, month: number, id: string) {
    const yearQuery: string =  `?year=${year}`;
    const monthQuery: string = `&month=${month}`;
    const idQuery: string = this.id ? `&id=${id}` : '';

    return `/newsletters${yearQuery}${monthQuery}${idQuery}`;
  }


  activateId(id: string) {
    this.id = id;
    this.router.navigateByUrl(this.getNavigateQuery(this.year, this.month, this.id));
  }

  deactivateId(id: string) {
    if (this.id === id) {
      this.id = undefined
      this.router.navigateByUrl(this.getNavigateQuery(this.year, this.month, this.id));
    }
  }
}
