<!--<a [href]="'#/portfolio/'+portfolio.id">-->
<!--  <div class="member portfolio-item"  style="background-color: white" >-->
<!--    <div class="pic"><img #img [src]="portfolio.assets[0]" class="img-fluid" alt="" (error)="img.src = 'assets/img/missing/missing_800x600.png'"></div>-->
<!--    <div class="member-info" style="  background: rgba(92, 118, 141, 0.9);">-->
<!--      <h4>{{portfolio.title}}</h4>-->
<!--      &lt;!&ndash;      <span ellipsis style="height: 38px; padding-left: 5px; padding-right: 5px" >{{portfolio.subtitle}}</span>&ndash;&gt;-->
<!--    </div>-->
<!--  </div>-->
<!--</a>-->
<div *ngIf="portfolio.generalDescription === ''" class="product" style="padding-top: 30px">
    <div class="product-container">
      <div class="product-item">
        <div class="product-wrap" style="text-align: center; cursor: auto;">
          <img #img [src]="portfolio.assets[0]" (error)="img.src='assets/img/missing/missing_800x600.png'" class="img-fluid" alt="">
          <div class="product-info">
            <h4>{{portfolio.title}}</h4>
            <p  style="min-height: 21px">
              <span *ngIf="portfolio.generalDescription "style="min-height: 21px" >Ver mais...</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
<a *ngIf="portfolio.generalDescription !== ''" class="product" [href]="'#/portfolio/'+portfolio.id" style="cursor: pointer">
    <div style="padding-top: 30px">
      <div class="product-container">
        <div class="product-item">
          <div class="product-wrap" style="text-align: center; cursor: auto;">
            <img #img [src]="portfolio.assets[0]" (error)="img.src='assets/img/missing/missing_800x600.png'" class="img-fluid" alt="">
            <div class="product-info" style="cursor: pointer">
              <h4>{{portfolio.title}}</h4>
              <p  style="min-height: 21px">
                <span *ngIf="portfolio.generalDescription" style="min-height: 21px" >Ver mais...</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
</a>
