import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {OwlOptions} from 'ngx-owl-carousel-o';
declare const $: any;
declare const AOS: any;


@Component({
  selector: 'app-carousel-wrapper',
  templateUrl: './carousel-wrapper.component.html',
  styleUrls: ['./carousel-wrapper.component.css']
})
export class CarouselWrapperComponent implements OnInit, AfterViewInit, OnChanges {

  @Input() assets: string[];
  @Input() alternative: string;

  customOptions: OwlOptions = {
    animateIn: true,
    animateOut: true,
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: true,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      }
    },
    nav: false
  }

  constructor() { }

  ngOnInit(): void {

  }

  ngAfterViewInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
  }

}
