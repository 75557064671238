<section  class="portfolio" >
  <div class="container" >
    <div fxLayout="column" >

      <div class="section-title text-center mb-0 pb-0" style="width: 100%">
        <div fxLayout="column" fxLayoutAlign="center center">
          <h2>Newsletters</h2>
        </div>
      </div><!-- Title -->

      <div class="contact newsletter"  >
        <div class="info-box" >
          <div class="row">
            <div class="col-lg-12">

              <div style="margin-bottom: 5px">
                <owl-carousel-o [options]="yearFilterOptions" >
                  <ng-template *ngFor="let year of this.years; let i = index" carouselSlide>
                    <div class="filter"
                         (click) = activateYearFilter(year);
                         [ngClass]="activeYearFilter.value === year ? 'filter-active': ''"
                    >
                      {{year}}
                    </div>
                  </ng-template>
                </owl-carousel-o>
              </div>

              <owl-carousel-o [options]="monthFilterOptions" >
                <ng-template *ngFor="let month of this.months; let i = index" carouselSlide>
                  <div class="filter"
                       (click) = "monthHasNewsletter[i] ? activateMonthFilter(i) : $event.stopPropagation()"
                       [ngClass]="{
                        'filter-active': activeMonthFilter.value === i,
                        'filter-disabled': !monthHasNewsletter[i]
                       }"
                  >
                    {{month}}
                  </div>
                </ng-template>
              </owl-carousel-o>

            </div>
          </div>
        </div>
      </div> <!-- Filters -->

        <mat-accordion *ngFor="let _newsletter of filteredNewsletters; let i = index"    >
          <mat-expansion-panel #panel class="shadow-container" [expanded]="_newsletter.ID === this.id" (afterExpand)="activateId(_newsletter.ID)" data-aos="fade-up" (afterCollapse)="deactivateId(_newsletter.ID)"  [attr.data-aos-delay]="i * 100" >
            <mat-expansion-panel-header [ngStyle.lt-sm]="'padding-top: 50px; padding-bottom: 50px'">
              <mat-panel-title style="flex-grow: 0">
                {{getDateString(_newsletter.Date)}}
              </mat-panel-title>
              <mat-panel-description>
                <strong>{{_newsletter.Name}}</strong>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div [innerHTML]="_newsletter.HTMLContent | safeHtml">
            </div>
          </mat-expansion-panel>
        </mat-accordion>

    </div>
  </div>


</section>
