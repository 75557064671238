import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {Product} from '../../our-products/our-product.model';
import {Newsletter} from '../../newsletters/newsletters.model';
import {NavigationEnd, Router} from '@angular/router';
import {ProductEntityService} from '../../our-products/store/product-entity.service';
import {ScrollService} from '../../../services/scroll.service';
import {DropdownGraph} from '../../dropdown/dropdown.model';
import {map} from 'rxjs/operators';
declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})

export class HeaderComponent implements OnInit, OnDestroy, AfterViewInit {

  private subscriptions: Subscription[] = [];
  products$: Observable<Product[]>
  favouriteNewsletters$: Observable<Newsletter[]>
  dropdownGraph: DropdownGraph<Product>;
  private _startPage: boolean;

  constructor(
    private router: Router,
    private productsEntityService: ProductEntityService,
    private scrollService: ScrollService
  ) {
    this.router.events.subscribe(next => {
      if(next instanceof NavigationEnd) {
        if(next.url !== '/home' && next.url !== '/') {
          this._startPage = false;
        }
        else {
          this._startPage = true;
        }
      }
    })
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.productsEntityService.getAll().subscribe(next =>
        this.dropdownGraph = new DropdownGraph(next)
      )
    )

    this.products$ = this.productsEntityService.entities$
      .pipe(
        map(products => {
            this.dropdownGraph = new DropdownGraph(products);
            return products.filter(item => item.level === 1)
          }
        )
      )
  }

  ngAfterViewInit() {

    $(window).scroll(function() {
      if ($(this).scrollTop() > 100) {
        $('#header').addClass('header-scrolled');
      } else {
        $('#header').removeClass('header-scrolled');
      }
    });

    if ($(window).scrollTop() > 100) {
      $('#header').addClass('header-scrolled');
    }

    // Stick the top-bar at top on scroll
    $("#header").sticky({
      topSpacing: 0,
      zIndex: '50'
    });


    if ($('.nav-menu').length) {

      $(document).on('click', '.mobile-nav-toggle', function(e) {
        $('body').toggleClass('mobile-nav-active');
        $('.mobile-nav-toggle i').toggleClass('icofont-navigation-menu icofont-close');
        $('.mobile-nav-overly').toggle();
      });

      $(document).on('click', '.mobile-nav .drop-down > a', function(e) {
        e.preventDefault();
        $(this).next().slideToggle(300);
        $(this).parent().toggleClass('active');
      });

      $(document).click(function(e) {
        var container = $(".mobile-nav, .mobile-nav-toggle");
        if (!container.is(e.target) && container.has(e.target).length === 0) {
          if ($('body').hasClass('mobile-nav-active')) {
            $('body').removeClass('mobile-nav-active');
            $('.mobile-nav-toggle i').toggleClass('icofont-navigation-menu icofont-close');
            $('.mobile-nav-overly').fadeOut();
          }
        }
      });
    } else if ($(".mobile-nav, .mobile-nav-toggle").length) {
      $(".mobile-nav, .mobile-nav-toggle").hide();
    }

  }


  ngOnDestroy() {
    for (let subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
    $(window).scroll().off()
    $(document).click().off()
  }

  get startPage()  {
    return this._startPage;
  }

  scrollTo(elementId: string) {
    this.scrollService.scrollTo(elementId)
  }

  toggleMobileMenu() {
      $('body').toggleClass('mobile-nav-active');
      $('.mobile-nav-toggle i').toggleClass('icofont-navigation-menu icofont-close');
      $('.mobile-nav-overly').toggle();
  }

  getChildren(product: Product): Product[] {
    return this.dropdownGraph.getChildren(product);
  }

  goTo(s: string, s2: string) {
    const currentUrl = this.router.url;
    if (s !== currentUrl){
      this.router.navigateByUrl(s);
    }
    if (s !== currentUrl && s2 !=='#top-bar'){
      setTimeout(() => {
        this.scrollService.scrollTo(s2)
      }, 1000)
    } else {
      if (s === currentUrl) {
        setTimeout(() => {
          this.scrollService.scrollTo(s2)
        }, 250)
      }
    }

  }
}
