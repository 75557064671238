export enum ResourceType {
  FICHA_TECNICA = 'ft',
  INSTRUCOES = 'ins',
  CERTIFICADO_CE = 'ce',
  CERTIFICADO_AMBIENTE = 'amb',
  MANUAL = 'ma',
  LISTA_VARIAVEIS = 'var',
  OUTRO = 'xxx',
}

const nameMaps = new Map<ResourceType, string>([
  [ResourceType.FICHA_TECNICA, 'Ficha Técnica'],
  [ResourceType.INSTRUCOES, 'Instruções'],
  [ResourceType.CERTIFICADO_CE, 'Certificado CE'],
  [ResourceType.CERTIFICADO_AMBIENTE, 'Certificado Ambiente'],
  [ResourceType.MANUAL, 'Manual'],
  [ResourceType.LISTA_VARIAVEIS, 'Lista de Variáveis'],
  [ResourceType.OUTRO, 'Outro'],
]);

export function getResourceTypeName(resourceType: ResourceType): string {
  return nameMaps.get(resourceType) || 'Outro';
}
