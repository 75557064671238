export class Newsletter {

  ID: string
  Name: string;
  Subject: string;
  HTMLContent: string;
  Date: number;

}

export function selectNewsletterId(newsletter: Newsletter): string {
  return newsletter.ID;
}
