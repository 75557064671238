import {Injectable} from '@angular/core';
import {EntityCollectionServiceBase, EntityCollectionServiceElementsFactory} from '@ngrx/data';
import {ProductDataService} from './product-data.service';
import {Product} from '../our-product.model';
import {DropdownGraph} from '../../dropdown/dropdown.model';
import {Observable} from 'rxjs';
import {last, map, switchMap} from 'rxjs/operators';

@Injectable()
export class ProductEntityService extends EntityCollectionServiceBase<Product> {

  constructor(private productElementsFactory: EntityCollectionServiceElementsFactory,
              private productDataService: ProductDataService) {
    super('Product', productElementsFactory);
  }

  get dropdownGraph$(): Observable<DropdownGraph<Product>> {
    return this.productDataService.dropdownGraph$;
  }

  hasModels(product: Product): boolean {
    return this.productDataService.hasModels(product);
  }

  hasFamily(product: Product) {
    return this.productDataService.hasFamily(product);
  }

  getParent(product: Observable<Product>): Observable<Product> {
    return this.productDataService._dropdownGraph$.pipe(
      switchMap(graph => product.pipe(
        map(productValue => graph.getParents(productValue)),
        map(parents => parents[parents.length - 1]),
      ),
    ));
  }
}
