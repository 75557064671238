import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {NewslettersSectionComponent} from './newsletters-section/newsletters-section.component';
import {EntityDataService, EntityDefinitionService, EntityMetadataMap} from '@ngrx/data';
import {selectNewsletterId} from './newsletters.model';
import {NewslettersDataService} from './store/newsletters-data.service';
import {NewslettersEntityService} from './store/newsletters-entity.service';
import {FlexModule} from '@angular/flex-layout';
import {EllipsisModule} from 'ngx-ellipsis';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NewslettersRoutingModule} from './newsletters-routing.module';

const entityMetaData: EntityMetadataMap = {
  //Must be the same name of the Entity Model
  Newsletter: {
    selectId: selectNewsletterId
  },
}

@NgModule({
  declarations: [NewslettersSectionComponent],
  exports: [NewslettersSectionComponent],
  imports: [
    NewslettersRoutingModule,
    CommonModule,
    FlexModule,
    EllipsisModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [
    NewslettersEntityService,
    NewslettersDataService
  ]
})
export class NewslettersModule {

  constructor(eds: EntityDefinitionService,
              entityDataService: EntityDataService,
              newsLettersDataService: NewslettersDataService,
  )
  {
    eds.registerMetadataMap(entityMetaData);
    entityDataService.registerService('Newsletter', newsLettersDataService);
  }

}
