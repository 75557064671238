<header id="header" class="header-scrolled">
  <div  class="container">

    <div class="logo float-left">
<!--&lt;!&ndash;      <h1 class="text-light"><a href="index.html"><span>Malvar</span></a></h1>&ndash;&gt;-->
       <a routerLink="/home" (click)="scrollTo('#top-bar')" style="cursor:pointer;"><img src="../../../../assets/img/logo/logo.png" alt="" class="img-fluid"></a>
    </div>


        <nav class="nav-menu float-right d-none d-lg-block">
      <ul>
        <li><a (click)="goTo('/home','#top-bar')" >Início</a></li>
        <li><a (click)="goTo('/home','#about')" >Sobre Nós</a></li>
        <li><a (click)="goTo('/home','#services')" >Serviços</a></li>
        <ng-container *ngIf="(products$ | async) as products">
        <ng-container *ngIf="dropdownGraph">
          <li *ngIf="products.length > 0" class="drop-down"><a (click)="goTo('/home','#product')">Produtos</a>
            <ul #menu>
              <li *ngFor="let product of products; " [className]="getChildren(product).length > 0 ? '': ''">
                <a routerLink="/product-browser" [queryParams]="[product.id]" >{{product.name}}</a>
              </li> <!-- Products Menu -->
            </ul>
          </li> <!-- Produtos -->
        </ng-container>
        </ng-container>
        <li><a (click)="goTo('/home','#newsletters')">Newsletters</a></li>
        <li><a (click)="goTo('/home','#portfolio')">Portefólio</a>
        <li><a href="https://anemos.pt/" target="_blank">Loja</a></li>
        <li><a (click)="goTo('/home', '#contact')">Contactos</a></li>
        <li fxShow.gt-sm fxShow="false"><a (click)="searchComponent.openDialog('80vw', '20vh', '10vw')"><i class="icofont-search"></i></a></li>
      </ul>
    </nav><!-- .nav-menu -->

    <nav class="mobile-nav d-lg-none">
      <ul>
        <li><a (click)="goTo('/home', '#top-bar')">Início</a></li>
        <li><a (click)="goTo('/home','#about')" >Sobre Nós</a></li>
        <li><a (click)="goTo('/home','#services')" >Serviços</a></li>
        <ng-container *ngIf="(products$ | async) as products">
          <ng-container *ngIf="dropdownGraph">

        <li *ngIf="products.length > 0" class="drop-down"><a>Produtos</a>
          <ul #menu>
            <li><a (click)="goTo('/home','#product'); toggleMobileMenu()">Ver todos</a></li>
            <li *ngFor="let product of products; " [className]="getChildren(product).length > 0 ? 'drop-down': ''">
              <a >{{product.name}}</a>
              <ul *ngIf="getChildren(product).length > 0">
                <li>
                  <a [routerLink]="'/product-browser'" [queryParams]="[product.id]" (click)="toggleMobileMenu()">Ver todos</a>
                </li>
<!--                <li *ngFor="let child of getChildren(product)">-->
<!--                  <a [routerLink]="'/product-browser'" [queryParams]="[child.id]" (click)="toggleMobileMenu()">{{child.name}}</a>-->
<!--                </li>-->
              </ul> <!-- Products Line -->
            </li>
          </ul>
        </li> <!-- Produtos -->
          </ng-container>
        </ng-container>
        <li><a (click)="goTo('/home','#newsletters')">Newsletters</a>
<!--        <li><a (click)="goTo('/home','#portfolio')">Portefólio</a>-->
        <li><a href="https://anemos.pt/" target="_blank">Loja</a></li>
        <li><a (click)="goTo('/home', '#contact')">Contactos</a>
        <li fxShow.sm fxShow="false"><a  (click)="toggleMobileMenu(); searchComponent.openDialog('80vw', '20vh', '10vw')"><i class="icofont-search"></i></a></li>
        <li fxShow.xs fxShow="false"><a (click)="toggleMobileMenu(); searchComponent.openDialog('90vw', '20vh', '5vw')"><i class="icofont-search"></i></a></li>
<!--        <li><a (click)="scrollTo('#news')" >Newsletters</a></li> &lt;!&ndash; News &ndash;&gt;-->
        <!--        <li *ngIf="favouriteNewsletters.length > 0"><a href="/#newsletters-section">Novidades</a>-->
        <!--        </li> &lt;!&ndash; Newsletters &ndash;&gt;-->
      </ul>
    </nav><!-- .nav-menu -->
    <button type="button" class="mobile-nav-toggle d-lg-none"><i class="icofont-navigation-menu"></i></button>
    <div class="mobile-nav-overly"></div>
  </div>
</header><!-- End Header -->
<app-search #searchComponent></app-search>

