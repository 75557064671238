import {Component, Input, OnInit} from '@angular/core';
import {DropdownGraph} from './dropdown.model';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.css']
})
export class DropdownComponent implements OnInit {

  @Input() items: DropdownGraph<any>[];

  constructor() { }

  ngOnInit(): void {
  }

}
