import {AfterViewInit, Component, OnInit} from '@angular/core';
import {NewslettersEntityService} from '../store/newsletters-entity.service';
import {Newsletter} from '../newsletters.model';
import {Observable, Subscription} from 'rxjs';

declare const mootrack: any;

@Component({
  selector: 'app-newsletters-section',
  templateUrl: './newsletters-section.component.html',
  styleUrls: ['./newsletters-section.component.css']
})
export class NewslettersSectionComponent implements OnInit, AfterViewInit {

  favourites$: Observable<Newsletter[]>;
  subscriptions: Subscription[] = [];

  constructor(
    private newslettersEntityService: NewslettersEntityService,
  ) {}

  ngOnInit(): void {
    // this.subscriptions.push(
    //   this.newslettersEntityService
    //     .getAll()
    //     .subscribe(next => {
    //       console.log(next)
    //     })
    // )
    this.favourites$ = this.newslettersEntityService.favourites$;
  }

  ngAfterViewInit() {

  }

  getDateString(_seconds: number): string {
    return new Date(_seconds).toLocaleDateString()
  }

  retrieveParams(newsletter: Newsletter): string {
    const date = new Date(newsletter.Date);
    return `?year=${date.getFullYear()}&month=${date.getMonth()}&id=${newsletter.ID}`
  }
}
