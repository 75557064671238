import {Pipe, PipeTransform} from '@angular/core';
import {Product} from '../modules/our-products/our-product.model';
import {FilterChildPipe} from './filter-child.pipe';

@Pipe({
  name: 'filterProductChild'
})
export class FilterProductChildPipe extends FilterChildPipe<Product> implements PipeTransform {

  transform(value: Product[], filter: string): Product[] {
    return super.transform(value, filter) as Product[];
  }

}
