<section class="product-details">
  <div class="container" *ngIf="portfolio">

    <div class="product-description pt-0">

      <div class="default-button mb-3" (click)="goToPortfolio()">
        <i class="icofont-arrow-left mr-2"></i>Portefólio
      </div>

      <h2>{{portfolio.title}}</h2>
      <div [fxLayout.lt-md]="'column'" [fxLayout.gt-sm]="'row'" fxLayoutGap="24px">
        <div [fxFlex.gt-sm]="40">
          <app-carousel-wrapper #carousel [assets]="portfolio.assets" [alternative]="'assets/img/missing/missing_1200x350.png'" ></app-carousel-wrapper>

        </div>
        <div [fxFlex.gt-sm]="60">
          <div fxLayout="column" style="height: 100%">
            <h3><strong>{{portfolio.subtitle}}</strong></h3>
            <div class="mb-3">
              {{portfolio.generalDescription}}
            </div>
            <div fxFlex>
              {{portfolio.integrationDescription}}
            </div>
          </div>
        </div>
      </div>
      <h2 class="mt-3">
        Detalhes
      </h2>
      <ul style="padding-left: 0">
        <li *ngFor="let item of portfolio.items" class="detail-item">
          <strong>{{item.name}}</strong>
          <ul *ngIf="item.subItems?.length > 0">
            <li *ngFor="let subitem of item.subItems" class="detail-subItem">
              {{subitem}}
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</section><!-- End Portfolio Details Section -->
