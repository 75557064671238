import {Component, Input, OnInit} from '@angular/core';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-basic-loading',
  templateUrl: './basic-loading.component.html',
  styleUrls: ['./basic-loading.component.css']
})
export class BasicLoadingComponent implements OnInit {

  @Input() loading$: Observable<boolean> = new Observable<boolean>();
  @Input() loaded$: Observable<boolean> = new Observable<boolean>();
  @Input() loadingText: string = 'A carregar...';

  constructor() { }

  ngOnInit(): void {
  }

}
