import { Pipe, PipeTransform } from '@angular/core';
import {Resource} from '../modules/our-products/resources/resource.interface';
import {ResourceType} from '../modules/our-products/resources/resource-type.enum';

@Pipe({
  name: 'manual'
})
export class ManualPipe implements PipeTransform {

  transform(value: Resource[]): Resource {
    const result: Resource | undefined = value.filter(item => item.resourceType === ResourceType.FICHA_TECNICA).shift()
    return result || value[0];
  }

}
