import {Component, Input, OnInit} from '@angular/core';
import {Product} from '../our-products/our-product.model';
import {ProductEntityService} from '../our-products/store/product-entity.service';
import {take} from 'rxjs/operators';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Overlay} from '@angular/cdk/overlay';
import {Router} from '@angular/router';
import {DropdownGraph} from '../dropdown/dropdown.model';
import {Newsletter} from '../newsletters/newsletters.model';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {


  constructor(
    public dialog: MatDialog,
    public overlay: Overlay
  ) {
  }

  openDialog(width: string, top: string, left: string) {
    this.dialog.closeAll();
    let dialogRef = this.dialog.open(SearchDialog, {
      // maxHeight: '60vh',
      maxWidth: width,
      width: width,
      position: {top: top, left: left},
      panelClass: 'my-dialog-container',
      scrollStrategy: this.overlay.scrollStrategies.noop()
    })

      dialogRef.afterOpened().pipe(take(1)).subscribe(next => {
        document.body.style.overflowY = 'hidden';
      })

      dialogRef.afterClosed().pipe(take(1)).subscribe(next => {
        document.body.style.overflowY = 'auto';
      });
  }

  ngOnInit(): void {
  }

}

@Component({
  selector: 'search-dialog',
  templateUrl: 'search-dialog.html',
  styleUrls: ['./search.component.css']
})
export class SearchDialog implements OnInit{

  constructor(
    private router: Router,
    private dialogRef: MatDialogRef<SearchDialog>,
    private productsEntityService: ProductEntityService
  ) {
  }

  ngOnInit() {

  }

  searchTerm: string = '';

  goToProduct(product: Product) {
    this.productsEntityService
      .getAll()
      .pipe(take(1))
      .subscribe(next => {

        let productsGraph: DropdownGraph<Product> = new DropdownGraph(next);
        if (product.productType === 'model') {
          product = productsGraph.getParents(product).pop();
        }
        this.router.navigateByUrl(`/product-browser?0=${product.id}`)
        this.dialogRef.close();

      })

  }

  goToNewsletter(newsletter: Newsletter) {
    let date: Date = new Date(newsletter.Date);
    this.router.navigateByUrl(`/newsletters?year=${date.getFullYear()}&month=${date.getMonth()}&id=${newsletter.ID}`);
    this.dialogRef.close();
  }
}
