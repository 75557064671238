<div class="container" style="padding: 3rem">
  <div class="row first">
    <div class="col-lg-12">
      <h2 class="page-header">RGPD</h2>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <h3>Regulamento de Proteção de Dados Pessoais <i>da Malvar Controls, Lda.</i></h3>
      <p>O presente Regulamento define o âmbito de aplicação o Tratamento de Dados Pessoais da <i>Malvar Controls, Lda.</i>.
        <br>Abrange o Tratamento de Dados Pessoais de Trabalhadores, Formandos, Clientes e Fornecedores (pessoas singulares)
      </p>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <ul class="list-unstyled project-details">
        <li>
          <strong>Âmbito da aplicação</strong>
          <br>A <i>Malvar Controls, Lda.</i> respeita as normas legais relativas à proteção de dados pessoais dos seus Trabalhadores, Candidatos a emprego, Formandos, Clientes e Fornecedores (Pessoas Singulares), designadamente aquelas que emanam de disposições legais nacionais e comunitárias e deliberações da Comissão Nacional de Proteção de Dados.
        </li>
        <li>
          <br><strong>Dados Pessoais dos Trabalhadores</strong>
          <br>O tratamento dos dados pessoais do trabalhador, no âmbito da reboral, decorre do conteúdo contratual e do cumprimento de obrigações legais, perante a Autoridade Tributária, a Segurança Social, a Autoridade para as Condições do Trabalho e demais entidades oficiais.
          <br>O tratamento dos dados pessoais dos trabalhadores, é reservado ao Responsável de Tratamento de Dados designado a cada momento, e com acesso limitado devidamente justificados nos termos da Lei, e em qualquer caso com salvaguarda da confidencialidade.
          <br>Em qualquer altura o trabalhador poderá, a seu pedido, aceder aos seus dados protegidos e solicitar a sua alteração ou correção, caso haja erro ou incompletude.
          <br>Os trabalhadores gozam, em conformidade com a Lei, dos direitos de informação, acesso e oposição ao tratamento dos seus dados pessoais. Para o exercício desses direitos de acesso e de oposição, têm de apresentar, por escrito, o pedido ao Responsável pelo Tratamento de Dados da Empresa através do Formulário IMP 0001 Proteção de Dados que poderá solicitar nos Serviços Administrativos.
          <br>Os trabalhadores podem, nos termos legais, exercer o direito ao esquecimento dos seus dados pessoais, exceto nos casos previstos para o cumprimento de obrigações legais.
          <br>O tratamento dos dados clínicos dos trabalhadores, obedece ao regime de tratamento de dados sensíveis e são, por isso, exclusivamente de acesso ao médico da empresa ou à equipa médica certificada, tendo o trabalhador acesso aos mesmos desde que previamente os solicite diretamente ao médico de trabalho.
          <br>Com a celebração do contrato de trabalho, e durante toda a execução, o trabalhador dá o seu consentimento a que os seus dados pessoais possam ser armazenados sob forma digital segura, processados e acedidos nos termos previamente ou pontualmente especificados.
          <br><br>
          <ul>
            <li>
              <strong>Finalidade do Tratamento de Dados Pessoais dos Trabalhadores</strong>
              <br>Os dados pessoais de trabalhadores poderão ser recolhidos e tratados pela <i>Malvar Controls, Lda.</i> com as seguintes finalidades:
              <br>
              <ol type="a">
                <li> Gestão administrativa; </li>
                <li> Cálculo e pagamento de retribuições, prestações, abonos e subsídios, e outros assuntos relativos a este tipo de processamento; </li>
                <li> Cálculo e retenção na fonte relativos a descontos na remuneração, obrigatórios ou facultativos, decorrentes de disposição legal; </li>
                <li> Execução de decisão ou sentença judicial, bem como tratamento de pedidos formulados pelos trabalhadores; </li>
                <li> Processamento de certificados de formação pela entidade empregadora e/ou por entidades formadoras externas; </li>
                <li> Emissão de bilhetes de viagem, vistos e outra documentação decorrente da necessidade de viagens por parte do trabalhador; </li>
                <li> Registos e controlo de assiduidade e/ou de acessos, incluindo registos de viagem; </li>
                <li> Cumprimento de obrigações legais no âmbito da segurança e saúde no trabalho. </li>

              </ol>
            </li>
            <br>
            <li>
              <strong>Categorias de Dados Pessoais a recolher</strong>
              <br>Para as finalidades acima referidas, a <i>Malvar Controls, Lda.</i> poderá recolher e tratar os dados pessoais bem como o original e cópias dos respetivos documentos do trabalhador que se incluam nas seguintes categorias:
              <br>
              <ol type="a">
                <li> Dados de identificação; </li>
                <li> Situação familiar; </li>
                <li> Dados relativos à atividade profissional;</li>
                <li> Dados relativos a retribuições.</li>
              </ol>
            </li>
            <br>
            <li>
              <strong>Prazo de conservação de dados</strong>
              <br>Para a finalidade de gestão administrativa de trabalhadores, certificados de formação e documentos necessários à emissão de bilhetes de viagem e/ou vistos, os dados podem ser conservados por um período legal após a cessação da relação de trabalho, e demais obrigações contabilísticas e fiscais.
              <br>Para efeitos de retribuições, prestações e regalias de trabalhadores, os dados podem ser conservados pelo período previsto na Lei.
              <br>O prazo dos respetivos dados poderá ser prolongado, por motivos de ação judicial, após a transferência dos dados às instituições judiciárias ou o trânsito em julgado da sentença.
              <br>Para efeitos de pensões, previdência ou do pagamento de prestações complementares posteriores devidas em momento posterior à cessação da relação de trabalho, os dados estritamente necessários à prova da qualidade de trabalhador, tempo de serviço e evolução da remuneração poderão ser conservados pelos prazos legais por correspondência a cada finalidade.
            </li>
            <br>
            <li>
              <strong>Destinatários dos Dados Pessoais dos Trabalhadores</strong>
              <br>São eventualmente destinatários dos dados pessoais:
              <br>
              <ol type="a">
                <li>Entidades a quem os dados devam ser comunicados por força de disposição legal ou a pedido do titular dos dados;</li>
                <li>Instituições financeiras que gerem as contas da <i>Malvar Controls, Lda.</i>, destinadas ao pagamento da retribuição dos trabalhadores;</li>
                <li>Entidades gestoras de Fundos de Pensões ou do Regime de Previdência;</li>
                <li>Companhias de seguros com quem é celebrado o contrato de seguro de acidentes de trabalho e/ou de acidentes pessoais (se aplicável);</li>
                <li>Entidades formadoras para a emissão de certificados de formação;</li>
                <li>Agências de viagem ou empresas de transportes para a emissão de documentação necessária a viagens;</li>
                <li>Gabinetes ou departamentos de contabilidade para efeito de processamento salarial ou de obrigações contabilísticas da Empresa;</li>
                <li>Entidades auditoras (internas e externas) no âmbito dos processos de certificação;</li>
                <li>Entidades consultoras externas no âmbito da sua prestação de serviços de consultadoria;</li>
                <li>Entidades que no âmbito de Medicina e Segurança no Trabalho asseguram a cada momento o cumprimento dessas obrigações na <i>Malvar Controls, Lda.</i>;</li>
                <li>Entidades que asseguram a gestão informática no tratamento de dados pessoais.</li>
              </ol>
            </li>
            <br>
            <li>
              <strong>Entidades externas (subcontratadas)</strong>
              <br>As entidades externas (subcontratadas) a que, no âmbito do presente regulamento, sejam facultados os dados pessoais dos trabalhadores, ficam sujeitas em termos contratuais ao cumprimento das obrigações legais em matéria de proteção de dados que são imputadas ao Responsável pelo tratamento dos dados.
            </li>
            <br>
            <li>
              <strong>Fotografias / Filmagens / Gravações</strong>
              <br>É proibido fotografar, filmar ou proceder a qualquer tipo de gravação ou outro processo de cópia e/ou reprodução de documentos pessoais, sem o consentimento do titular dos dados, exceto nos casos previstos na Lei ou devidamente autorizados por entidade competente para o efeito.
            </li>
          </ul>
        </li>
        <br>
        <li>
          <strong>Dados Pessoais dos Candidatos a emprego</strong>
          <br>A <i>Malvar Controls, Lda.</i> garante a salvaguarda do direito à proteção dos dados dos candidatos a emprego que sejam prestados voluntariamente e autorizados pelo titular dos dados, os quais serão tratados confidencialmente, nos termos da Lei em vigor.
          <br>A política de privacidade de recrutamento da <i>Malvar Controls, Lda.</i>, encontra-se definida em documento próprio que poderá ser solicitada nos Serviços Administrativos.
        </li>
        <br>
        <li>
          <strong>Dados Pessoais dos Formandos</strong>
          <br>O tratamento dos dados pessoais dos formandos decorre do conteúdo contratual e do cumprimento de obrigações legais perante as entidades oficiais.
          <br>O tratamento dos dados pessoais dos formandos é reservado apenas ao Responsável de Tratamento de Dados designado a cada momento e com acesso limitado devidamente justificados nos termos da Lei e, em qualquer caso, com salvaguarda da confidencialidade.
          <br>Em qualquer altura o formando poderá a seu pedido aceder aos seus dados protegidos e solicitar a sua alteração ou correção, caso haja erro ou incompletude.
          <br>Os formandos gozam, em conformidade com a Lei, dos direitos de informação, acesso e oposição ao tratamento dos seus dados pessoais. Para o exercício desses direitos de acesso e de oposição, têm de apresentar, por escrito, o pedido ao Responsável do Tratamento de Dados da <i>Malvar Controls, Lda.</i>.
          <br>Os formandos podem, nos termos legais, exercer o direito ao esquecimento dos seus dados pessoais, exceto nos casos previstos para o cumprimento de obrigações legais.
          <br>Com a celebração do contrato de formação, e durante toda a execução, o formando dá o seu consentimento a que os seus dados pessoais, possam ser, armazenados sob forma digital segura, processados e acedidos nos termos previamente especificados ou pontualmente especificados.
          <br>Aos formandos aplica-se, em matéria de proteção de dados, o regime previsto neste regulamento para os dados pessoais Trabalhadores, em termos de processamento de recolha, finalidade do tratamento, categoria de dados a recolher, prazo de conservação, destinatários dos dados, com as devidas adaptações e se aplicável.
        </li>
        <br>
        <li>
          <strong>Dados Pessoais dos Clientes e Fornecedores</strong>
          <br>A empresa <i>Malvar Controls, Lda.</i> recolhe, utiliza e conserva dados pessoais fornecidos pelos Clientes e Fornecedores, nos termos permitidos pela legislação aplicável, de forma adequada à execução da relação contratual com os mesmos e para utilização e faturação dos serviços.
          <br>Cliente e Fornecedor, no âmbito da relação contratual, comercial e ainda no interesse legitimo e de prossecução da atividade da <i>Malvar Controls, Lda.</i>, autorizam a introdução dos seus dados pessoais num ficheiro da empresa e a sua cessão pela mesma a terceiras entidades, localizadas na União Europeia, para o seu tratamento no âmbito da finalidade deste contrato, ainda que para fins de marketing, comerciais ou outros, inquéritos de satisfação de Clientes e informação sobre os produtos e serviços da <i>Malvar Controls, Lda.</i>.
          <br>O Cliente e Fornecedor deverá notificar a <i>Malvar Controls, Lda.</i>, caso se verifiquem modificações nos dados pessoais que afetem a relação contratual e/ou a faturação dos serviços.
          <br>Os dados necessários para a execução do contrato e/ou faturação dos serviços poderão ser armazenados e utilizados pela <i>Malvar Controls, Lda.</i> mesmo após o fim do processo ou contrato, nos termos legais e até se completar a faturação e/ou obrigações legais decorrentes da relação comercial e/ou contratual.
        </li>
        <br>
        <li>
          <strong>Utilização do website da <i>Malvar Controls, Lda.</i></strong>
          <br>Caso seja utilizador do nosso <i>website</i> através do processo de registo de utilizador e por “Nome de utilizador/Palavra-passe”, os seus dados estarão armazenados numa base de dados específica de controlo de utilizadores. Poderá requerer a sua eliminação sempre que pretender, através do pedido por escrito para a nossa morada presente no nosso <i>website</i>. A eliminação dos dados acarreta que não poderá utilizar o <i>website</i> em modo registado, a partir desse momento, a não ser que crie uma nova conta de utilizador.
          <br>Caso utilize o nosso <i>website</i> de forma não registada e entre em contacto com a <i>Malvar Controls, Lda.</i> através de formulários de contacto, os dados pedidos nesses formulários poderão ficar armazenados. Poderá também requerer a sua eliminação e continuar a utilizar o nosso <i>website</i> normalmente.
        </li>
        <br>
        <li>
          <strong>Disposições Finais - As obrigações em matéria de Proteção de Dados Pessoais</strong>
          <br>A <i>Malvar Controls, Lda.</i> ou organização que individualmente ou em conjunto com outra (subcontratada) determine as finalidades e os meios dos tratamentos dos dados é o “responsável pelo tratamento” e deve, nessa medida, entre outros aspetos, assegurar que:
          <br><br>
          <ol type="a">
            <li>Os dados pessoais são recolhidos para finalidades determinadas, explícitas e legítimas e não sejam posteriormente tratados de forma incompatível com as finalidades da recolha;</li>
            <li>Apenas são recolhidos os dados pessoais adequados, pertinentes e não excessivos relativamente às finalidades da recolha;</li>
            <li>Os dados pessoais recolhidos são exatos e atualizados;</li>
            <li>Os dados pessoais apenas são conservados durante o período necessário para a prossecução das finalidades da recolha/tratamento (garantindo o cumprimento das Deliberações da CNPD aplicáveis e da legislação específica aplicável a determinados sectores de atividade);</li>
            <li>São disponibilizadas ao titular dos dados todas as informações relacionadas com o tratamento efetuado, concedendo-lhe o direito de acesso, retificação e eliminação dos seus dados, bem como a oposição ao seu tratamento, nos termos da Lei;</li>
            <li>Os titulares dos dados podem requerer junto do Responsável do Tratamento, o exercício dos seus direitos;</li>
            <li>É obtido o consentimento do titular para o tratamento dos dados, nos casos em que tal é exigível;</li>
            <li>O tratamento dos dados encontra-se devidamente notificado à CNPD (se aplicável) e, quando legalmente exigido, é obtida a respetiva autorização prévia, ou devidamente regulamentado nos termos legais;</li>
            <li>Que os colaboradores autorizados a aceder aos dados pessoais, estão vinculados ao dever de confidencialidade;</li>
            <li>Que foram celebrados com as entidades subcontratantes em matéria de tratamento de dados pessoais dos nossos titulares de dados, os respetivos contratos escritos de salvaguarda da confidencialidade e privacidade;</li>
            <li>Que foram implementadas as medidas técnicas e organizativas adequadas para proteger os dados pessoais, contra a destruição, acidental ou ilícita, a alteração, acesso não autorizados e divulgação e contra qualquer forma de tratamento ilícito;</li>
            <li>Que se procede nos termos legais ao registo das atividades de tratamento de dados pessoais.</li>
          </ol>
        </li>
        <br>
        <li>
          <strong>Documentação Complementar do RGPD</strong>
          <br><br>
          <ul>
            <li>Formulário: IMP 0001 Proteção de Dados (para exercício dos direitos dos titulares dos dados);</li>
            <li>Política de privacidade e de utilização do nosso site;</li>
            <li>Política de privacidade de recrutamento;</li>
            <li>Segurança da informação - manual do colaborador</li>
            <li>Formulário: Registo de atividades de tratamento de dados pessoais</li>
          </ul>
        </li>
        <br>
        <li>
          <u>Identificação e contactos:</u>
          <br><u>Para qualquer questão relacionada com a política de privacidade e de proteção de dados pessoais pode contactar-nos via correio-electrónico para <a href="mailto:rgpd@malvar.pt">rgpd@malvar.pt</a>, ou por carta dirigida ao Responsável pelo Tratamento de Dados da <i>Malvar Controls, Lda.</i>, Rua da Boavista, 36 - 1.º Andar, 4770-754 Vermoim</u>
        </li>
      </ul>
      <p>O Presente Regulamento entra em vigor a 25 de Maio de 2018</p>
    </div>
  </div>
</div>
