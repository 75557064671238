import {DefaultDataService, DefaultDataServiceConfig, HttpUrlGenerator} from '@ngrx/data';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {Portfolio, PortfolioGallery} from '../portfolio.model';
import {map} from 'rxjs/operators';
import {Product} from '../../our-products/our-product.model';
import {DropdownGraph} from '../../dropdown/dropdown.model';

const defaultDataServiceConfig: DefaultDataServiceConfig = {
  root: environment.backend,
  timeout: 3000, // request timeout
}

@Injectable()
export class PortfolioDataService extends DefaultDataService<Portfolio> {

  _loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  _tags: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  _portfolios: BehaviorSubject<Portfolio[]> = new BehaviorSubject<Portfolio[]>([]);
  _filteredPortfolios: BehaviorSubject<Portfolio[]> = new BehaviorSubject<Portfolio[]>([]);
  _activeTag: BehaviorSubject<string> = new BehaviorSubject<string>('Todos');
  headers;

  constructor(
    http: HttpClient,
    httpUrlGenerator: HttpUrlGenerator,
  ) {
    super('Portfolio', http, httpUrlGenerator, defaultDataServiceConfig);
    this._loading.next(true);
    this.getGallery().subscribe(gallery => {

      this._tags.next(['Todos', ...gallery.tags]);
      this._portfolios.next(gallery.portfolios);

      // Applying tag filter at index 0 makes sure that all portfolios are shown.
      this.applyTagFilter('Todos', 0);

      this._loading.next(false);
    })
  }


  getAll(): Observable<Portfolio[]>{
    return this._portfolios.asObservable();
  }

  getGallery(): Observable<PortfolioGallery>{
    return this.getJSON();
  }

  getById(key:number | string): Observable<Portfolio>{
    return this.getJSON()
      .pipe(
        map(item => item.portfolios.find(portfolio => portfolio.id === key))
      )
  }

  private getJSON(): Observable<PortfolioGallery> {


    let headers = new HttpHeaders({
      'Cache-Control':  'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
      'Pragma': 'no-cache',
      'Expires': '0'
    });
      return this.http.get('./assets/data/portfolio-catalogue.json', {headers: headers}) as Observable<PortfolioGallery>
  }

  getLoading(): Observable<boolean>{
    return this._loading.asObservable();
  }

  getPortfolios(): Observable<Portfolio[]>{
    return this._portfolios.asObservable();
  }

  getFilteredPortfolios(): Observable<Portfolio[]>{
    return this._filteredPortfolios.asObservable();
  }

  getActiveTag(): Observable<string> {
    return this._activeTag.asObservable();
  }

  applyTagFilter(tag: string, index: number): void {
    this._activeTag.next(tag);

    if (index === 0) {
      this._filteredPortfolios.next(this._portfolios.value);
      return;
    }

    let filteredPortfolios = this._portfolios.value.filter(portfolio => portfolio.tags.indexOf(tag) !== -1);
    this._filteredPortfolios.next(filteredPortfolios);
  }

  getByPage(page: number, pageSize: number): Observable<Portfolio[]> {
    return of(this._filteredPortfolios.value.slice(page * pageSize, page * pageSize + pageSize));
  }
}


