import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {MainContainerComponent} from './containers/main-container/main-container.component';
import {PageNotFoundComponent} from './pages/page-not-found/page-not-found.component';
import {PortfolioModule} from './modules/portfolio/portfolio.module';
import {identifierModuleUrl} from '@angular/compiler';
import {NewslettersModule} from './modules/newsletters/newsletters.module';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: '',
    component: MainContainerComponent,
    children: [
      {
        path: 'home',
        loadChildren: () => import('./pages/start-page/start-page.module').then(m => m.StartPageModule)
      },
      {
        path: 'product-browser',
        loadChildren: () => import('./modules/our-products/pages/product-browser/product-browser.module').then(m => m.ProductBrowserModule)
      },
      {
         path: 'portfolio',
         loadChildren: () => import('./modules/portfolio/portfolio-routing.module').then(m => PortfolioModule)
      },
      {
        path: 'newsletters',
        loadChildren: () => import('./modules/newsletters/newsletters-routing.module').then(m => NewslettersModule)
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'home'
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'top'})],
  exports: [RouterModule]
})
export class AppRouting { }
