<ng-container *ngIf="searchResults | async as search">
  <div class="search-result">
    <h3 *ngIf="search.length > 0"><strong>Produtos</strong></h3>
    <div *ngFor="let result of search.slice(0, showingResults)" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px" class="search-result-row"
         (click)="_itemSelected(result.object)"
    >
      <div>
        <img #img [src]="'assets/' +result.object.assets[0]" (error)="img.src='assets/img/logo/logo_75px.png'" >
      </div>
      <div>
        <strong>{{result.object.name}}</strong>
      </div>
      <div fxFlex="" *ngIf="result.object.description !== 'x'">
        {{result.object.description}}
      </div>
    </div>
    <div style="width: 100%;" class="text-center">
      <button *ngIf="showingResults < searchResults.getValue().length" (click)="showMore()">Ver mais produtos</button>
    </div>
  </div>
</ng-container>
