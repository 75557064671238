import {Component, Input, OnInit} from '@angular/core';
import {Product} from '../../our-product.model';
import {BreakpointObserver, Breakpoints, BreakpointState} from '@angular/cdk/layout';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-product-model',
  templateUrl: './product-model.component.html',
  styleUrls: ['./product-model.component.css']
})
export class ProductModelComponent implements OnInit {

  @Input() product: Product;
  @Input() maxWidth: number;
  isMobile$: Observable<BreakpointState>;

  constructor(public breakpointService: BreakpointObserver) { }

  ngOnInit(): void {
    this.isMobile$ = this.breakpointService.observe(Breakpoints.HandsetPortrait);
  }

}
