<!-- ======= Our portfolio Section ======= -->
<section id="portfolio" class="portfolio">
  <div class="container">

    <div class="section-title">
      <h2 >Portefólio</h2>
    </div>



    <div class="row">
      <ng-container   *ngFor="let portfolio of portfolios$ | async" >
        <app-portfolio-item class="col-lg-3 col-md-6" [portfolio]="portfolio"></app-portfolio-item>
      </ng-container>
    </div>

  </div>
  <div class="text-center">
    <a href="#/portfolio">Visitar o nosso portefólio completo</a>
  </div>

</section><!-- End Our portfolio Section -->
