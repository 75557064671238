import {Resource} from './resources/resource.interface';

export class Product {

  id:string;
  level:number;
  name: string;
  description: string;
  assets: string[];
  resources: Resource[];
  productType: string; //"family" | "subfamily" | "model";
}

export function selectProductId(product: Product): string {
  return product?.id;
}
