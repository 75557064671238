import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SearchComponent, SearchDialog} from './search.component';
import {FlexLayoutModule, FlexModule} from '@angular/flex-layout';
import {FormsModule} from '@angular/forms';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {SearchProductComponent} from './search-product/search-product.component';
import {RouterModule} from '@angular/router';
import { SearchNewslettersComponent } from './search-newsletters/search-newsletters.component';



@NgModule({
  declarations: [SearchComponent, SearchDialog, SearchProductComponent, SearchNewslettersComponent],
  exports: [SearchComponent],
    imports: [
        CommonModule,
        FlexModule,
        FlexLayoutModule,
        FormsModule,
        MatDialogModule,
        MatButtonModule,
        RouterModule
    ],
  entryComponents: [
    SearchDialog
  ]
})
export class SearchModule { }
