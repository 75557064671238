import {Component} from '@angular/core';
import {ProductEntityService} from '../../our-products/store/product-entity.service';
import {Product} from '../../our-products/our-product.model';
import {SearchEngine} from '../search-engine';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-search-product',
  templateUrl: './search-product.component.html',
  styleUrls: ['./search-product.component.css']
})
export class SearchProductComponent extends SearchEngine<Product>  {

  constructor(
    private productsEntityService: ProductEntityService
  ) {
    super();
  }

  getSourceProperties(): string[] | string {
    return ['name', 'description'];
  }

  getDataSource(): Observable<Product[]> {
    return this.productsEntityService.getAll();
  }

}
