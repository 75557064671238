import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRouting } from './app.routing';
import { AppComponent } from './app.component';
import {EntityDataModule} from '@ngrx/data';
import {HttpClientModule} from '@angular/common/http';
import {StoreModule} from '@ngrx/store';
import {metaReducers, reducers} from './reducers';
import {EffectsModule} from '@ngrx/effects';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {environment} from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {LegalsModule} from './modules/legals/legals.module';
import {MainContainerModule} from './containers/main-container/main-container.module';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import {ScrollService} from './services/scroll.service';
import {AgmCoreModule} from '@agm/core';
import { NewslettersListComponent } from './modules/newsletters/newsletters-list/newsletters-list.component';
import {ExtendedModule, FlexModule} from '@angular/flex-layout';
import {MatExpansionModule} from '@angular/material/expansion';
import {CarouselModule} from 'ngx-owl-carousel-o';
import { SafeHtmlPipe } from './safe-html.pipe';
import {ProgressModule} from './modules/progress/progress.module';

@NgModule({
    declarations: [
        AppComponent,
        NewslettersListComponent,
        SafeHtmlPipe,
    ],
    imports: [
        BrowserAnimationsModule,
        BrowserModule,
        AgmCoreModule.forRoot({
            apiKey: environment.maps
        }),
        AppRouting,
        MainContainerModule,
        LegalsModule,
        HttpClientModule,
        EffectsModule.forRoot([]),
        EntityDataModule.forRoot({}),
        StoreModule.forRoot(reducers, {
            metaReducers,
        }),
        StoreDevtoolsModule.instrument({maxAge: 25, logOnly: environment.production}),
        BrowserAnimationsModule,
        FlexModule,
        MatExpansionModule,
        CarouselModule,
        ExtendedModule,
        ProgressModule,
    ],
    providers: [
        {
            provide: LocationStrategy,
            useClass: HashLocationStrategy
        },
        ScrollService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {


}
