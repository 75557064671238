import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {Portfolio} from '../portfolio.model';
declare var $:any;
import {Location} from '@angular/common';
import {PortfolioEntityService} from '../store/portfolio-entity.service';
import {ActivatedRoute, Router} from '@angular/router';
import {take} from 'rxjs/operators';

@Component({
  selector: 'app-portfolio-item-detail',
  templateUrl: './portfolio-item-detail.component.html',
  styleUrls: ['./portfolio-item-detail.component.css']
})
export class PortfolioItemDetailComponent implements OnInit, AfterViewInit {

  portfolio: Portfolio
  location: Location;

  constructor(
    location: Location,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private portfolioEntityService: PortfolioEntityService
  ) {
    this.location = location;
  }

  ngOnInit(): void {
    // let id: number = this.activatedRoute.snapshot
      let id: string = this.activatedRoute.snapshot.params['id'];
        this.portfolioEntityService.getByKey(id).pipe(take(1)).subscribe(next => {
          this.portfolio = next;
          if(!next) {
            this.router.navigateByUrl('portfolio')
          }
        })
  }

  ngAfterViewInit() {
    $(".product-details-carousel").owlCarousel({
      autoplay: true,
      dots: true,
      loop: true,
      items: 1
    });
  }


  goToPortfolio() {
    this.router.navigateByUrl('portfolio')
  }
}
