import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {PortfolioGalleryComponent} from './portfolio-gallery/portfolio-gallery.component';
import {PortfolioItemDetailComponent} from './portfolio-item-detail/portfolio-item-detail.component';


const routes: Routes = [
  {
    path: '',
    component: PortfolioGalleryComponent,
  },
  {
    path: ':id',
    component: PortfolioItemDetailComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PortfolioRoutingModule { }
