import stringSimilarity from 'string-similarity';
import {EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {take} from 'rxjs/operators';

export interface SearchResult<T> {
  rating: number;
  object: T
}

export abstract class SearchEngine<T> implements OnInit, OnChanges {

  @Input() searchTerm: string;
  @Input() sensibility: number = 0.1;
  @Output() itemSelected: EventEmitter<T> = new EventEmitter<T>();
  searchResults: BehaviorSubject<SearchResult<T>[]> = new BehaviorSubject<SearchResult<T>[]>([]);
  searchTimeout: any;
  showingResults: number = 5;

  protected constructor() {
    this.showingResults = 5;
  }

  public recalculateSearch(searchTerm: string, _objects: Observable<T[]>, searchableProperties: string[] | string) {
    const res: SearchResult<T>[] = [];

    if (searchTerm.length > 0) {

      _objects.pipe(take(1)).subscribe(objects => {

        for (let object of objects) {

          let rating: number = 0;

          if (typeof searchableProperties === 'string') {
            searchableProperties = [searchableProperties];
          }

          for(let property of searchableProperties) {
            const tempRating = stringSimilarity.compareTwoStrings(object[property].toLowerCase(), searchTerm.toLowerCase());
            rating = tempRating > rating ? tempRating: rating;
          }

          if (rating >= this.sensibility) {
            res.push({
              rating: rating,
              object: object
            })
          }
        }
        this.searchResults.next(res.sort((item1, item2) => item2.rating - item1.rating));
      })
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.searchTerm) {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout)
      }
      this.searchTimeout = setTimeout(() => {
        this.recalculateSearch(this.searchTerm, this.getDataSource(), this.getSourceProperties());
      }, 250);
    }
  }

  ngOnInit(): void {};
  abstract getDataSource(): Observable<T[]>;
  abstract getSourceProperties(): string[] | string;

  showMore(): void {
    this.showingResults + 5 < this.searchResults.value.length ? this.showingResults += 5 : this.showingResults = this.searchResults.value.length;
  }

  _itemSelected(item: T): void {
    this.itemSelected.emit(item);
  }

}
