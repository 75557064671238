import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterChildPipe } from './filter-child.pipe';
import {FilterProductChildPipe} from './filter-product-child.pipe';
import {ManualPipe} from './manual.pipe';



@NgModule({
  declarations: [FilterChildPipe, FilterProductChildPipe, ManualPipe],
  imports: [
    CommonModule
  ],
  exports: [
    FilterChildPipe,
    FilterProductChildPipe,
    ManualPipe
  ]
})
export class MiscModule { }
