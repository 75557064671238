<owl-carousel-o [options]="customOptions">
  <ng-template *ngIf="assets.length === 0" carouselSlide>
    <img  [src]="alternative" [alt]="" >
  </ng-template>
  <ng-container *ngFor="let asset of assets; let i = index">
    <ng-template carouselSlide >
      <img #img [src]="asset" [alt]="" (error)="img.src=alternative">
    </ng-template>
  </ng-container>
  <ng-container *ngIf="assets.length === 0" >
  </ng-container>

</owl-carousel-o>
