
<section id="newsletters" class="about-lists section-bg">
  <div class="container">

    <div class="section-title">
      <h2>Newsletters</h2>
    </div>

    <div class="row no-gutters">
      <div *ngFor="let newsletter of favourites$ | async; let i = index" class="col-lg-4 col-md-6 content-item" [attr.data-aos]="'fade-up'" [attr.data-aos-delay]="i*250">
        <div fxLayout="column" style="min-height: 200px">
          <div>
            <h4  style="font-size: 22px;"> {{newsletter.Name}}</h4>
          </div>
          <div>
            <span >{{getDateString(newsletter.Date)}}</span>
          </div>
          <div fxFlex fxLayoutAlign="start center">
            <p ellipsis style="max-height: 80px">{{newsletter.Subject}}</p>
          </div>
          <div>
            <a class="fake-link" [href]="'#/newsletters'+[retrieveParams(newsletter)]">Ler mais</a>
          </div>
        </div>
      </div>
      <div class="col-12 text-center mt-3" [attr.data-aos]="'fade-up'">
        <a href="/#/newsletters">Ver newsletters anteriores</a>
        <div data-mooform-id="cedefbb1-316c-4bdb-b5d9-7122de1a5b04" class="p-0"></div>
      </div>
    </div>
  </div>

</section><!-- End About Lists Section -->
