import {Injectable} from '@angular/core';
import {EntityActionOptions, EntityCollectionServiceBase, EntityCollectionServiceElementsFactory} from '@ngrx/data';
import {Portfolio} from '../portfolio.model';
import {PortfolioDataService} from './portfolio-data.service';
import {Observable} from 'rxjs';
import {take} from 'rxjs/operators';

@Injectable()
export class PortfolioEntityService extends EntityCollectionServiceBase<Portfolio> {


  constructor(private serviceElementsFactory: EntityCollectionServiceElementsFactory,
              private portfolioDataService: PortfolioDataService) {
    super('Portfolio', serviceElementsFactory);
  }

  getAll(options?: EntityActionOptions): Observable<Portfolio[]> {
    return super.getAll(options);
  }

}
