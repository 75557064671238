<div class="container" style="padding: 3rem">
  <div class="row first">
    <div class="col-lg-12">
      <h2 class="page-header">Privacidade</h2>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <h3>Política de privacidade e de utilização do nosso site</h3>
      <p>A privacidade e segurança dos seus dados pessoais é de grande importância para a <i>Malvar Controls</i>. Por este motivo, pretendemos ser abertos e transparentes com o processamento de seus dados pessoais. A seguinte política de privacidade define o modo como seus dados pessoais serão processados e protegidos.</p>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <ul class="list-unstyled project-details">
        <li>
          <strong>Quem é o responsável pelo tratamento dos seus dados pessoais?</strong>
          <br>A <i>Malvar Controls</i> é a responsável pelo tratamento de dados pessoais que nos envia e responsabiliza-se pelos referidos dados pessoais de acordo com a legislação aplicável em matéria de proteção de dados.
          <br>Malvar Controls, Lda.
          <br>Rua da Boavista, 36 - 1.º Andar
          <br>4770-754 Vermoim VNF
          <br>Portugal
          <br>NIF: 513 108 378
          <br>e-mail: rgdp@malvar.pt
        </li>
        <li>
          <br><strong>Onde guardamos seus dados pessoais?</strong>
          <br>Os dados que recolhemos são guardados dentro do Espaço Económico Europeu (“EEE”).
        </li>
        <li>
          <br><strong>Quem tem acesso aos seus dados pessoais?</strong>
          <br>Não transferimos, vendemos ou trocamos os seus dados para terceiros para fins de marketing. Os dados disponibilizados a terceiros são utilizados apenas para lhe prestarmos os nossos serviços.
        </li>
        <li>
          <br><strong>Quais são os fundamentos legais para proceder ao tratamento de dados?</strong>
          <br>Sempre que processarmos dados pessoais, informaremos acerca do fundamento legal do seu tratamento, nomeadamente se se enquadra no cumprimento de uma obrigação legal, ou se é necessária para a execução de um contrato, e quais os seus efeitos se optar por não o fazer.
        </li>
        <li>
          <br><strong>Quais são seus direitos?</strong>
          <br><u><i>Direito de acesso:</i></u>
          <br>Qualquer pessoa tem o direito de solicitar informações sobre os dados pessoais que coletamos a qualquer momento. Para isso, basta entrar em contato por e-mail com a <i>Malvar Controls</i> e forneceremos os seus dados pessoais guardados.
          <br><br><u><i>O direito à portabilidade:</i></u>
          <br>Sempre que a <i>Malvar Controls</i> processar automaticamente seus dados pessoais, seja com base no seu consentimento ou num contrato, tem o direito de obter uma cópia dos seus dados transferidos. Isso incluirá apenas os dados pessoais que nos enviou.
          <br><br><u><i>O direito à retificação:</i></u>
          <br>Tem o direito de solicitar a retificação dos seus dados pessoais se as informações estiverem incorretas ou desatualizadas. Isso inclui o direito de adicionar ou complementar dados pessoais incompletos ou ausentes.
          <br><br><u><i>O direito ao esquecimento:</i></u>
          <br>A qualquer momento, tem o direito de apagar todos os dados pessoais processados pela Malvar Controls, exceto nas seguintes situações:
          <p></p>
          <ul class="sub-privacy-details">
            <li>
              tem uma questão em aberto com o Serviço de Atendimento ao Cliente;
            </li>
            <li>
              tem uma encomenda em aberto que ainda não foi enviada ou foi enviada parcialmente;
            </li>
            <li>
              tem uma dívida pendente com a <i>Malvar Controls</i>, independentemente do método de pagamento;
            </li>
            <li>
              utilizou, ou suspeita-se que tenha utilizado indevidamente os nossos serviços nos últimos quatro anos;
            </li>
            <li>
              a sua dívida foi vendida a terceiros nos últimos três anos ou no último ano, no caso de clientes falecidos;
            </li>
            <li>
              caso tenha efetuado uma compra, iremos manter os seus dados pessoais relacionados com a transação para fins de contabilidade.
            </li>
          </ul>
          <u><i>O direito de se opor ao tratamento de dados pessoais com fundamento nos interesses legítimos do responsável:</i></u>
          <br>Tem o direito de se opor ao tratamento de dados pessoais que se fundamente no interesse legítimo da <i>Malvar Controls</i>. A <i>Malvar Controls</i> não continuará a proceder ao tratamento dos dados pessoais, exceto se pudermos demonstrar um fundamento legítimo para o tratamento que se sobreponha aos seus interesses e direitos, ou em caso de processos judiciais.
          <br><br><u><i>O direito se opor ao marketing direto:</i></u>
          <br>Tem o direito de se opor ao marketing direto, incluindo definições de perfil realizadas para fins de marketing direto.
          <br><br><u><i>Direito à limitação:</i></u>
          <br>Tem o direito de solicitar que a <i>Malvar Controls</i> limite o tratamento de dados pessoais nas seguintes circunstâncias:
          <p></p>
          <ul class="sub-privacy-details">
            <li>
              se pretender se opor ao tratamento que tenha por base o interesse legítimo da <i>Malvar Controls</i>, a <i>Malvar Controls</i> irá restringir todo o processamento desses dados até que o interesse legítimo seja verificado;
            </li>
            <li>
              se declarar que os seus dados pessoais estão incorretos, a <i>Malvar Controls</i> terá de restringir todo o tratamento desses dados até que a correção dos mesmos seja verificada;
            </li>
            <li>
              se o tratamento for ilícito, pode opor-se à eliminação dos dados pessoais e, em vez disso, solicitar a restrição de utilização dos seus dados pessoais;
            </li>
            <li>
              se a <i>Malvar Controls</i> já não necessitar dos dados pessoais, mas tiver de mantê-los para sua defesa em processos judiciais.
            </li>
          </ul>
        </li>
        <li>
          <br><strong>Como pode exercer os seus direitos?</strong>
          <br>Levamos a proteção de dados pessoais muito a sério, pelo que dispomos de técnicos de atendimento ao cliente dedicados e sensibilizados para lidar com os seus pedidos relacionados com os direitos acima indicados. Pode entrar em contacto com eles através do e-mail <a href="mailto:rgpd@malvar.pt">rgpd@malvar.pt</a>.
        </li>
        <li>
          <br><strong>Atualizações ao nosso Aviso de Privacidade:</strong>
          <br>Poderemos ter necessidade de atualizar o nosso Aviso de Privacidade. A versão mais recente do Aviso de Privacidade está sempre disponível no nosso website. Iremos comunicar quaisquer alterações significativas ao Aviso de Privacidade, como, por exemplo, a finalidade com que usamos os seus dados pessoais, a identidade do responsável pelo tratamento ou os seus direitos.
        </li>
      </ul>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <h3>Atendimento ao Cliente</h3>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <ul class="list-unstyled project-details">
        <li>
          <strong>Para que usamos seus dados pessoais?</strong>
          <br>Usamos seus dados pessoais para gerir as suas solicitações, reclamações ou dúvidas sobre a garantia de produtos e assuntos de assistência técnica. Também poderemos contatá-lo diretamente se houver algum problema com o seu pedido.
        </li>
        <li>
          <br><strong>Que tipo de dados pessoais processamos?</strong>
          <br>Processaremos todos os dados que nos forneça, incluindo as seguintes categorias:
          <ul class="sub-project-details">
            <li>
              informações de contato, como nome, endereço de e-mail e número de telefone;
            </li>
            <li>
              informações de pagamento e histórico de pagamento;
            </li>
            <li>
              informações de crédito;
            </li>
            <li>
              informações de encomenda;
            </li>
            <li>
              toda a correspondência relevante no assunto exposto.
            </li>
          </ul>
        </li>
        <li>
          <br><strong>Quem tem acesso aos seus dados pessoais?</strong>
          <br>Quando qualquer um dos seus dados pessoais é compartilhado com terceiros, os dados são usados exclusivamente para nos permitir fornecer os serviços mencionados acima.
        </li>
        <li>
          <br><strong>Em que base legal processamos seus dados?</strong>
          <br>O processamento dos seus dados pessoais como base interesses legítimos.
        </li>
        <li>
          <br><strong>Por quanto tempo mantemos os seus dados?</strong>
          <br>Manteremos os seus dados pelo período legal estabelecido para cada situação em análise.
        </li>
        <li>
          <br><strong>Direito de oposição ao tratamento de dados pessoais com base em interesse legítimo:</strong>
          <br>Tem o direito de se opor ao processamento dos seus dados pessoais, com base no interesse legítimo da <i>Malvar Controls</i>. A <i>Malvar Controls</i> não continuará a tratar os dados pessoais, a não ser que possamos demonstrar uma razão legítima para tal tratamento que invalide os seus interesses e direitos ou devido a razões legais.
        </li>
      </ul>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <h3>Marketing direto</h3>
    </div>
  </div>
  <div class="row" >
    <div class="col-md-12">
      <ul class="list-unstyled project-details">
        <li>
          <strong>Porque tratamos os seus dados pessoais?</strong>
          <br>Usamos seus dados pessoais para enviar ofertas de marketing, pesquisas de informações, convites por e-mail, mensagens de texto, telefonemas e correio postal.
          <br>Para otimizar sua experiência com a <i>Malvar Controls</i>, forneceremos informações relevantes, recomendaremos produtos e enviaremos ofertas personalizadas. Alguns desses serviços são baseados em compras anteriores e nas informações que compartilhou connosco.
        </li>
        <li>
          <br><strong>Que tipo de dados pessoais processamos?</strong>
          <br>Processamos as seguintes categorias de dados pessoais:
          <ul>
            <li>
              informações de contato, como endereço de e-mail, número de telefone e código postal;
            </li>
            <li>
              histórico de compras.
            </li>
          </ul>
        </li>
        <li>
          <br><strong>Quem tem acesso aos seus dados pessoais?</strong>
          <br>Os dados disponibilizados a terceiros são utilizados apenas para fornecer os serviços mencionados acima. Nunca transmitimos, vendemos ou trocamos os seus dados pessoais com terceiros fora do grupo Regin para fins de <i>marketing</i>.
        </li>
        <li>
          <br><strong>Qual o fundamento legal de tratamento dos seus dados pessoais?</strong>
          <br>O tratamento dos seus dados pessoais é baseado no seu consentimento, quando aceita receber <i>marketing</i> direto. Exceto no envio de <i>marketing</i> por correio postal, que também se aplica a catálogos que são enviados, com base no nosso interesse legítimo.
        </li>
        <li>
          <br><strong>O seu direito de revogar o seu consentimento:</strong>
          <br>qualquer momento, tem o direito de retirar seu consentimento do processamento de seus dados pessoais, bem como rejeitar <i>marketing</i> direto.
          <br>Se optar por fazê-lo, a <i>Malvar Controls</i> não poderá enviar mais ofertas de <i>marketing</i> direto nem qualquer outra informação com base no seu consentimento.
          <br>Pode desativar o <i>marketing</i> direto pelo e-mail <a href="mailto:rgpd@malvar.pt">rgpd@malvar.pt</a>.
        </li>
        <li>
          <br><strong>Durante quanto tempo guardamos os seus dados pessoais?</strong>
          <br>Manteremos seus dados pessoais para fins de <i>marketing</i> direto até que retire o seu consentimento.
        </li>
      </ul>
      <p>01-01-2022</p>

    </div>
  </div>
</div>
