<div class="page" fxLayout="column" fxLayoutAlign="start none" style="height:100%" >
  <app-top-bar></app-top-bar>
  <app-header></app-header>
  <div fxFlex >
    <router-outlet></router-outlet>
  </div>
  <div>
    <app-footer></app-footer>
  </div>
</div>



