<section id="top-bar" class="d-none d-lg-block">
  <div class="container clearfix">
    <div class="contact-info float-left">
      <i class="icofont-envelope"></i><a href="mailto:geral@malvar.pt">geral@malvar.pt</a>
      <i class="icofont-phone"></i> (+ 351) 252 990 340 <span style="font-size: xx-small; font-style: italic">chamada para rede fixa nacional</span>
    </div>
    <div class="social-links float-right">
<!--      <a href="#" class="twitter"><i class="icofont-twitter"></i></a>-->
<!--      <a href="#" class="instagram"><i class="icofont-instagram"></i></a>-->
<!--      <a href="#" class="skype"><i class="icofont-skype"></i></a>-->
      <a href="https://pt-pt.facebook.com/malvarcontrols/" class="facebook"><i class="icofont-facebook"></i></a>
      <a href="https://www.linkedin.com/company/malvar-controls/" class="linkedin"><i class="icofont-linkedin"></i></a>
    </div>
  </div>
</section>
