import { Component, OnInit } from '@angular/core';
import {SearchEngine} from '../search-engine';
import {Newsletter} from '../../newsletters/newsletters.model';
import {Observable} from 'rxjs';
import {NewslettersEntityService} from '../../newsletters/store/newsletters-entity.service';

@Component({
  selector: 'app-search-newsletters',
  templateUrl: './search-newsletters.component.html',
  styleUrls: ['./search-newsletters.component.css']
})
export class SearchNewslettersComponent extends SearchEngine<Newsletter> {

  constructor(
    private newslettersEntityService: NewslettersEntityService
  ) {
    super();
  }

  getDataSource(): Observable<Newsletter[]> {
    return this.newslettersEntityService.getAll();
  }

  getSourceProperties(): string[] | string {
    return ['Name', 'Subject'];
  }

  getDateString(date: number): string {
    return new Date(date).toLocaleDateString();

  }
}
