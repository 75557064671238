<div class="portfolio container">
  <div class="title">
    Portefólio
  </div>

<!--  <ng-container *ngIf="loading; else portfolioListTemplate">-->
<!--    <app-basic-loading *ngIf="loading" [loaded$]="loaded$" [loading$]="loading$"></app-basic-loading>-->
<!--  </ng-container>-->

    <div class="portfolio-container contact">
      <div class="info-box p-3">
        <div class="tags-container">
          <ng-container *ngIf="activeTag$ | async as activeTag">
            <ng-container *ngFor="let tag of tagList$ | async; let i = index">
              <div class="mb-0 tag" [ngClass]="{'filter-active': tag === activeTag}" (click)="tagChange(tag, i)">{{tag}}</div>
            </ng-container>
          </ng-container>
        </div>
      </div>

      <div #scrollContainer class="portfolio-list">
        <ng-container *ngFor="let portfolio of this.portfolioList">
          <app-portfolio-item [portfolio]="portfolio"></app-portfolio-item>
        </ng-container>
      </div>

      <div style="text-align: center">
        <button *ngIf="hasMore" type="button" class="default-button" (click)="loadNextPage()">Ver mais...</button>
      </div>
    </div>


</div>

