import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {BasicLoadingComponent} from './basic-loading/basic-loading.component';
import {FlexModule} from '@angular/flex-layout';



@NgModule({
  declarations: [BasicLoadingComponent],
  exports: [BasicLoadingComponent],
  imports: [
    CommonModule,
    FlexModule
  ]
})
export class ProgressModule { }
