import {DefaultDataService, DefaultDataServiceConfig, HttpUrlGenerator} from '@ngrx/data';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import {Newsletter} from '../newsletters.model';
import {map} from 'rxjs/operators';

const defaultDataServiceConfig: DefaultDataServiceConfig = {
  root: environment.backend,
  timeout: 3000, // request timeout
}

@Injectable()
export class NewslettersDataService extends DefaultDataService<Newsletter> {

  headers;

  constructor(
    http: HttpClient,
    httpUrlGenerator: HttpUrlGenerator,
  ) {
    super('Newsletter', http, httpUrlGenerator, defaultDataServiceConfig);
    // this.headers = new HttpHeaders();
    // this.headers.append('Accept', 'application/json');
    // this.headers.append('Content-Type', 'application/json');
    // this.headers.append('Access-Control-Allow-Origin', '*');
    // this.headers.append('Access-Control-Allow-Credentials', 'true');
    // this.headers.append("Access-Control-Allow-Methods", "POST, GET, OPTIONS, PUT, DELETE");
    // this.headers.append("Access-Control-Allow-Headers", "Content-Type, Content-Length, Accept-Encoding, X-CSRF-Token");
  }


  getAll(): Observable<Newsletter[]> {
    return this.getJSON()
      .pipe(
        map(item => item.sort((item1, item2) => {
          return item1.Date > item2.Date ? -1 : 1
        }))
      )
  }

  getFavourite(): Observable<Newsletter[]> {
    return this.getJSON()
      .pipe(
        map(item => item.sort((item1, item2) => item1.Date > item2.Date ? -1 : 1).slice(0, 6))
      )
  }

  getYears(): Observable<number[]> {
    return this.getJSON()
      .pipe(
        map(newsletters => {
          const result: number[] = [];
          for (let newsletter of newsletters) {
            const year: number = new Date(newsletter.Date).getFullYear();
            if (!result.some(item => item === year)) {
              result.push(year);
            }
          }
          return result.sort((item1, item2) => item1 - item2);
        })
      )
  }


  getWithYear(year: number): Observable<Newsletter[]> {
    return this.getJSON()
      .pipe(
        map(item => {
          return item.filter(item => new Date(item.Date).getFullYear() === year).sort( (item1, item2) => item2.Date - item1.Date)
        })
      )
  }

  private getJSON(): Observable<Newsletter[]> {

    let headers = new HttpHeaders({
      'Cache-Control':  'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
      'Pragma': 'no-cache',
      'Expires': '0'
    });

    return this.http.get('./assets/data/newsletters-catalogue.json', { headers: headers }) as Observable<Newsletter[]>;

  }

  private calculateYears(newsletters: Newsletter[]): number[] {
    const result: number[] = [];
    for (let newsletter of newsletters) {
      const year: number = new Date(newsletter.Date).getFullYear();
      if (!result.some(item => item === year)) {
        result.push(year);
      }
    }
    return result.sort((item1, item2) => item1 - item2);
  }



}


