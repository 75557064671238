<link rel="stylesheet" href="product-model.component.css">
<div class="container" *ngIf="isMobile$ | async as isMobile">

  <div class="model-row d-flex flex-column flex-lg-row
              align-items-center">
    <div class="img-container">
      <img #img class="img-fluid" [src]="'assets/' + product.assets[0]" (error)="img.src='assets/img/missing/missing_800x600.png'" [alt]="product.name + 'image'" >
    </div>
    <div class="product-label text-center text-lg-left" [ngStyle]="{'width.px': maxWidth > 200 ? 200: maxWidth, 'min-width.px': maxWidth > 200 ? 200: maxWidth}" >{{product.name}}</div>
    <div class="product-description text-justify flex-fill">
      {{product.description}}
    </div>
    <div class="d-flex flex-column justify-content-between flex-lg-column resources-container">
      <div *ngIf="product.resources | manual as resource; let i = index" style="cursor: pointer; margin-left: 20px">
        <a class="resource-label" [href]="'assets/' + resource.path" target="_blank">
          <i class="icofont-file-pdf acrobatish resource-icon"></i>
          Ficha Técnica
        </a>
      </div>

      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px" style="margin-left: 20px">
        <a class="resource-label" [href]="'http://www.anemos.pt/pt/produtos/' + product.id" target="_blank">
          <i class="icofont-external-link resource-icon"></i>
          Mais detalhes
        </a>
      </div>
    </div>
  </div>
</div>


<!--
<div [fxLayout]="'row'" [fxLayout.lt-md]="'column'" fxLayoutAlign="start center" fxLayoutAlign.lt-md="center start" fxLayoutGap="15px">
  <div [ngStyle.lt-md]="'width: 100%'" style="min-height: 100px" fxLayoutAlign ="center center">
    <img #img [ngStyle.lt-sm]="'max-width:100%'" [src]="'assets/' + product.assets[0]" (error)="img.src='assets/img/missing/missing_75x75.png'" >
  </div>
  <div [ngStyle.gt-xs]="{'min-width': this.maxWidth+'px'}">
    <strong>{{product.name}}</strong>
  </div>
  <div fxFlex class="model-description">
    {{product.description}}
  </div>
  <div fxLayout="column" fxLayoutAlign.xs="center center" fxLayoutGap="8px" style="width: 200px" [ngStyle.xs]="'width: fit-content'">
    <div *ngIf="product.resources | manual as resource; let i = index" style="cursor: pointer">
      <div fxLayout="column" fxLayoutGap="8px">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px" style="margin-left: 20px">
          <ng-container *ngIf="!resource.isLink">
            <a class="resource-label" [href]="contentRoot + resource.path" target="_blank">
              <i class="icofont-file-pdf acrobatish resource-icon"></i>
              {{getResourceTypeName(resource.resourceType)}}
            </a>
          </ng-container>
          <ng-container *ngIf="resource.isLink">
            <a *ngIf="resource.isLink" class="resource-label" [href]="resource.path" target="_blank">
              <i class="icofont-external-link resource-icon"></i>
              {{getResourceTypeName(resource.resourceType)}}
            </a>
          </ng-container>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px" style="margin-left: 20px">
          <a class="resource-label" [href]="'/#/product-browser/details/' + product.id">
            <i class="icofont-external-link resource-icon"></i>
            Mais detalhes
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
<hr style="margin-bottom: 0; margin-top: 8px">-->
