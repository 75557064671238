import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {PortfolioSectionComponent} from './portfolio-section/portfolio-section.component';
import {EntityDataService, EntityDefinitionService, EntityMetadataMap} from '@ngrx/data';
import {selectPortfolioId} from './portfolio.model';
import {PortfolioDataService} from './store/portfolio-data.service';
import {PortfolioEntityService} from './store/portfolio-entity.service';
import {EllipsisModule} from 'ngx-ellipsis';
import { PortfolioItemComponent } from './portfolio-item/portfolio-item.component';
import { PortfolioItemDetailComponent } from './portfolio-item-detail/portfolio-item-detail.component';
import {CarouselModule} from 'ngx-owl-carousel-o';
import {PortfolioRoutingModule} from './portfolio-routing.module';
import {PortfolioGalleryComponent} from './portfolio-gallery/portfolio-gallery.component';
import {FlexModule} from '@angular/flex-layout';
import {ProgressModule} from '../progress/progress.module';
import {CarouselWrapperModule} from '../carousel-wrapper/carousel-wrapper.module';
import {CdkVirtualScrollViewport, ScrollingModule} from '@angular/cdk/scrolling';

const entityMetadataMap: EntityMetadataMap = {
  Portfolio: {
    selectId: selectPortfolioId
  }
}

@NgModule({
  declarations: [PortfolioSectionComponent, PortfolioItemComponent, PortfolioItemDetailComponent, PortfolioGalleryComponent],
  exports: [PortfolioSectionComponent, PortfolioItemComponent, PortfolioItemDetailComponent, PortfolioGalleryComponent],
  imports: [
    CommonModule,
    EllipsisModule,
    CarouselModule,
    PortfolioRoutingModule,
    FlexModule,
    ProgressModule,
    CarouselWrapperModule,
    ScrollingModule,
  ],
  providers: [
    PortfolioDataService,
    PortfolioEntityService
  ]
})
export class PortfolioModule {

  constructor(
    eds: EntityDefinitionService,
    dataService: EntityDataService,
    portfolioDataService: PortfolioDataService
  ) {
    eds.registerMetadataMap(entityMetadataMap);
    dataService.registerService('Portfolio', portfolioDataService);
  }

}
