import {Component, Input, OnInit} from '@angular/core';
import {Portfolio} from '../portfolio.model';
declare const AOS: any;

@Component({
  selector: 'app-portfolio-item',
  templateUrl: './portfolio-item.component.html',
  styleUrls: ['./portfolio-item.component.css']
})
export class PortfolioItemComponent implements OnInit {

  @Input() portfolio: Portfolio;

  constructor() { }

  ngOnInit(): void {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out-back",
      once: true
    });
  }

}
