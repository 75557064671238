import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Portfolio} from '../portfolio.model';
import {fromEvent, Observable, Subscription} from 'rxjs';
import {PortfolioDataService} from '../store/portfolio-data.service';
import {debounceTime, distinctUntilChanged, first, map, switchMap, take, takeUntil} from 'rxjs/operators';

declare const $:any;

@Component({
  selector: 'app-portfolio-gallery',
  templateUrl: './portfolio-gallery.component.html',
  styleUrls: ['./portfolio-gallery.component.css']
})
export class PortfolioGalleryComponent implements OnInit, OnDestroy {

  @ViewChild('scrollContainer', { static: true }) scrollContainer!: ElementRef;

  ITEMS_PER_PAGE = 12;

  tagList$: Observable<string[]>;
  activeTag$: Observable<string>;
  hasMore: boolean = false;

  //Keeps the portfolio data set.
  portfolioList: Portfolio[] = [];
  isLoading: boolean = false;
  currentPage: number = 0;

  constructor(
    private portfolioDataService: PortfolioDataService
  ) {}

  ngOnInit(): void {

    this.tagList$ = this.portfolioDataService._tags;
    this.activeTag$ = this.portfolioDataService.getActiveTag();

    // Check if the initial content fills the viewport
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;
    const initialContentFilledViewport = windowHeight >= documentHeight;

    // If the initial content doesn't fill the viewport, listen for the scroll event
    // if (!initialContentFilledViewport) {
    //   this.setupScrollListener();
    // }

    this.loadFirstItems();

  }

  setupScrollListener() {

    // Subscribe to the scroll event and load more data when the user reaches the bottom.
    fromEvent(window, 'scroll')
      .pipe(
        debounceTime(0), // Adjust debounce time based on your requirements
        map(() => {
          const windowHeight = window.innerHeight;
          const documentHeight = document.documentElement.scrollHeight  // remove footer height;
          const scrollPosition = window.scrollY || window.pageYOffset || document.body.scrollTop || document.documentElement.scrollTop;
          const atBottom = windowHeight + scrollPosition >= documentHeight;
          return atBottom;
        }),
        distinctUntilChanged(),
        switchMap(atBottom => {
          if (atBottom && !this.isLoading) {
            this.isLoading = true;
            this.currentPage++;
            return this.portfolioDataService.getByPage(this.currentPage, this.ITEMS_PER_PAGE);
          }
          return [];
        })
      )
      .subscribe((newData: Portfolio[]) => {
        this.portfolioList = [...this.portfolioList, ...newData];
        this.isLoading = false;
      });
  }

  ngOnDestroy(): void {}

  getTags(portfolio: Portfolio): string {
    let result: string = '';
    for (let tag of portfolio.tags) {
      result += `${tag} `
    }
    return result
  }

  loadFirstItems() {
    this.portfolioDataService.getLoading().pipe(
      first(value => value === false)
    ).subscribe(loading => {
      this.portfolioDataService.getByPage(this.currentPage, this.ITEMS_PER_PAGE).pipe(
        take(1)
      ).subscribe(next => {
          this.currentPage++;
        this.hasMore = next.length >= this.ITEMS_PER_PAGE;
        this.portfolioList = next;
      })
    })
  }

  tagChange(tag: string, index: number) {
    this.currentPage = 0;
    this.portfolioDataService.applyTagFilter(tag, index);
    this.loadFirstItems();
  }

  loadNextPage() {
    this.portfolioDataService.getByPage(this.currentPage, this.ITEMS_PER_PAGE).pipe(
      take(1)
    ).subscribe(next => {
      this.currentPage++;
      this.hasMore = next.length >= this.ITEMS_PER_PAGE;
      this.portfolioList.push(...next);
    })
  }
}
