import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {HeaderComponent} from './header/header.component';
import {RouterModule} from '@angular/router';
import {OurProductsModule} from '../our-products/our-products.module';
import {DropdownModule} from '../dropdown/dropdown.module';
import {AngularStickyThingsModule} from '@w11k/angular-sticky-things'
import {NewslettersModule} from '../newsletters/newsletters.module';
import {SearchModule} from '../search/search.module';
import {FlexLayoutModule, FlexModule} from '@angular/flex-layout';
import {ProgressModule} from '../progress/progress.module';



@NgModule({
  declarations: [HeaderComponent],
  exports: [
    HeaderComponent
  ],
    imports: [
        CommonModule,
        NewslettersModule,
        OurProductsModule,
        RouterModule,
        DropdownModule,
        AngularStickyThingsModule,
        SearchModule,
        FlexModule,
        FlexLayoutModule,
        ProgressModule
    ]
})
export class HeadersModule { }
