export class PortfolioGallery {
  tags: string[];
  portfolios: Portfolio[];
}

export class Portfolio {
  id: string;
  title: string;
  subtitle: string;
  generalDescription: string;
  integrationDescription: string;
  highLight: boolean;
  tags: string[];
  assets: string[];
  items: PortfolioItem[];
}

export class PortfolioItem {

  name: string;
  subItems?: PortfolioItem[];

}

export function selectPortfolioId(portfolio: Portfolio): string {
  if (portfolio === undefined) return undefined
  return portfolio.id;
}
