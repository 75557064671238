import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarouselWrapperComponent } from './carousel-wrapper.component';
import {CarouselModule} from 'ngx-owl-carousel-o';



@NgModule({
    declarations: [CarouselWrapperComponent],
    exports: [
        CarouselWrapperComponent
    ],
    imports: [
        CommonModule,
        CarouselModule
    ]
})
export class CarouselWrapperModule { }
