import { Injectable } from '@angular/core';
import {element} from 'protractor';
declare var $:any;

@Injectable({
  providedIn: 'root'
})
export class ScrollService {

  constructor() { }

  scrollTo(elementId: string) {
    var scrolltoOffset = $('#header').outerHeight() - 2;
    var target= $(elementId);
    if (target.length) {
      // e.preventDefault();

      var scrollto = target.offset().top - scrolltoOffset;

      if (elementId === '#top-bar') {
        scrollto = 0;
      }

      if (scrollto >= 0) {
        $('html, body').animate({
          scrollTop: scrollto
        }, 1500, 'easeInOutExpo');
      }

      if ($(this).parents('.nav-menu, .mobile-nav').length) {
        $('.nav-menu .active, .mobile-nav .active').removeClass('active');
        $(this).closest('li').addClass('active');
      }

      if ($('body').hasClass('mobile-nav-active')) {
        $('body').removeClass('mobile-nav-active');
        $('.mobile-nav-toggle i').toggleClass('icofont-navigation-menu icofont-close');
        $('.mobile-nav-overly').fadeOut();
      }
      return false;
    }
  }

}
