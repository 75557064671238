<ng-container *ngIf="searchResults | async as search">
  <div class="search-result">
    <h3 *ngIf="search.length > 0"><strong>Newsletters</strong></h3>
    <div *ngFor="let result of search.slice(0, showingResults)" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px" class="search-result-row"
         (click)="_itemSelected(result.object)"
    >
      <div>
        <img #img [src]="'assets/img/logo/logo_75px.png'" >
      </div>
      <div >
        {{getDateString(result.object.Date)}}
      </div>
      <div fxFlex>
        <strong>{{result.object.Name}}</strong>
      </div>
    </div>
    <div style="width: 100%;" class="text-center">
      <button *ngIf="showingResults < searchResults.getValue().length" (click)="showMore()">Ver mais newsletters</button>
    </div>
  </div>
</ng-container>
