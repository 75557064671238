import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {PrivacyPolicyComponent} from './privacy-policy/privacy-policy.component';
import { CookiePolicyComponent } from './cookie-policy/cookie-policy.component';
import { RecruitmentPolicyComponent } from './recruitment-policy/recruitment-policy.component';
import { RgpdComponent } from './rgpd/rgpd.component';

@NgModule({
  declarations: [PrivacyPolicyComponent, CookiePolicyComponent, RecruitmentPolicyComponent, RgpdComponent],
  exports: [PrivacyPolicyComponent, CookiePolicyComponent, RecruitmentPolicyComponent, RgpdComponent],
  imports: [
    CommonModule
  ]
})
export class LegalsModule { }

