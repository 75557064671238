
<div class="container" style="padding: 3rem">
  <div class="row first">
    <div class="col-lg-12">
      <h2 class="page-header">Política de privacidade de recrutamento</h2>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12" >
      <p >A <i>Malvar Controls, Lda.</i> garante a salvaguarda do direito à proteção de dados, que sejam prestados voluntariamente e autorizados pelo titular dos dados, os quais serão tratados confidencialmente, nos termos da Lei em vigor.</p>
      <p>As informações e dados pessoais constantes do <i>Curriculum Vitae</i> e/ou outro documento similar e/ou formulário de contacto para bolsa de emprego, enviado ou preenchido por qualquer candidato a um posto de trabalho, por mail ou outro suporte, serão objeto de tratamento de forma automatizada, com a finalidade de recrutamento e seleção de candidatos.</p>
      <p>Ao fornecer as referidas informações e dados pessoais à <i>Malvar Controls, Lda.</i>, o candidato presta o seu consentimento para que as suas informações e os seus dados pessoais sejam objeto de operações de tratamento pela Empresa.</p>
      <p>Os referidos dados poderão ser comunicados a empresas do Grupo, bem como transferidos para a base de dados de outras empresas sediadas fora de Portugal pertencentes ao grupo (quando aplicável) com a finalidade de proporcionar uma maior área de oportunidade de emprego.</p>
      <p>As referidas informações e dados pessoais não serão comercializados ou cedidos a terceiros.</p>
      <p>Nos casos em que os recrutadores do Grupo se encontrem localizados em países fora do Espaço Europeu, a empresa garante ao titular dos dados que a referida transferência internacional se realiza de acordo com a Lei, nomeadamente em matéria de garantias, podendo aceder às mesmas através do espaço reservado para esse feito, no <i>site</i> da empresa.</p>
      <p>O fornecimento de eventuais dados sensíveis, apenas e só quando legalmente forem admissíveis, serão tratados para os fins de recrutamento e seleção de candidatos se forem relevantes e pertinentes para esses fins, considerando-se que os dados são fornecidos com base no consentimento do titular de dados.</p>
      <p>Os candidatos têm o direito, em qualquer momento, de acordo com a Lei em vigor, de acesso, retificação, apagamento, limitação ou oposição de tratamento dos dados que lhe digam respeito, devendo para tanto enviar uma carta para a morada da <i>Malvar Controls, Lda.</i> ou email para o endereço: <a href="mailto:rgpd@malvar.pt">rgpd@malvar.pt</a>, caso pretenda exercer esses direitos.</p>
      <p>Os candidatos têm ainda o direito de retirar o seu consentimento a qualquer momento, nos termos da Lei em vigor e de apresentar uma reclamação a uma autoridade de controlo.</p>
      <p>A <i>Malvar Controls, Lda.</i> conservará os dados pessoais dos candidatos pelo tempo necessário à realização dos fins para os quais forem recolhidos, e sem prejuízo do determinado na legislação laboral.</p>
      <p>Os dados recolhidos no âmbito da presente política de privacidade não constituem o cumprimento de uma obrigação legal ou contratual. Porém, caso o candidato seja selecionado, os dados pessoais serão usados para a elaboração do respetivo contrato.</p>
      <p>Os dados recolhidos não serão objeto de decisões automatizadas.</p>
      <p>Os dados poderão ser usados para definição de perfis com o único objetivo de determinar o candidato a selecionar.</p>
      <p>Caso o candidato não autorize ficar sujeito a uma decisão com base no perfil, deverá declarar expressamente ou abster-se de enviar uma candidatura.</p>
      <p>A <i>Malvar Controls, Lda.</i> implementou as medidas técnicas e organizativas adequadas tendo em vista a proteção dos dados pessoais contra a destruição acidental ou ilícita, a alteração, acesso não autorizados e divulgação e contra qualquer forma de tratamento ilícito.</p>
      <p>Os colaboradores autorizados a aceder aos dados pessoais, estão vinculados ao dever de confidencialidade.</p>
      <hr>
      <p >
        <u>Identificação e contactos:</u>
        <br>Para qualquer questão relacionada com a política de privacidade e de proteção de dados pessoais pode contactar-nos via correio-electrónico para <a href="mailto:rgpd@malvar.pt">rgpd@malvar.pt</a>, ou por carta dirigida ao Responsável pelo Tratamento de Dados da <i>Malvar Controls, Lda.</i>, Rua da Boavista, 36 - 1.º Andar, 4770-754 Vermoim
      </p>
      <p>30-05-2018</p>
    </div>
  </div>
</div>
