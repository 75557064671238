import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FooterComponent} from './footer.component';
import {LegalsModule} from '../legals/legals.module';
import {FlexModule} from '@angular/flex-layout';



@NgModule({
  declarations: [FooterComponent],
  exports: [FooterComponent],
  imports: [
    CommonModule,
    FlexModule,
    LegalsModule,
  ]
})
export class FooterModule { }
