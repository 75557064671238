import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-recruitment-policy',
  templateUrl: './recruitment-policy.component.html',
  styleUrls: ['./recruitment-policy.component.css']
})
export class RecruitmentPolicyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
