export type Edge = {
  start: any,
  end: any
}

export class DropdownGraph<T extends {id: string, level: number}> {

  private readonly roots: T[];
  private tree: T[] = [];
  private edges: Edge[] = [];

  constructor(objects: T[]) {

    let counter = 0;
    this.roots = objects.filter(item => item['level'] === 1)

    for (let index = 0 ; index < objects.length - 1; index++) {
      for (let support = index+1; support < objects.length ; support++ ) {
        if(objects[support].level === objects[index].level+1) {
          this.edges.push({start: objects[index], end: objects[support]});
        }
        if (objects[support].level === objects[index].level){
          break;
        }
      }
    }

  }

  getRoots(): any[] {
    return this.roots
  }

  getChildren(object: T): T[] {
    if (!object) {
      return this.roots
    }
    else {
      return this.edges.filter(item => item.start.id === object.id).map(value => value.end);
    }
  }


  getParents(object: T): T[] {
    let counter = 0;
    let result: any[] = [];
    let item = this.edges.find(item => item.end.id === object.id);
    while (item !== undefined && counter < 10) {
      result.push(item.start);
      counter++;
      item = this.edges.find(anotherItem => anotherItem.end === item.start);
    }

    return result.reverse();
  }
}

