import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterChild'
})
export class FilterChildPipe<T extends {name: string, description: string}> implements PipeTransform {

  transform(value: T[], filter: string): T[] {
    if (!filter) return value;
    return value.filter(item => {
      return item.name.toLowerCase().includes(filter.toLowerCase()) ||
        item.description.toLowerCase().includes(filter.toLowerCase())
    });
  }

}
