import {AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Product} from '../our-product.model';
import {ProductEntityService} from '../store/product-entity.service';
import {Observable, Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {map, take} from 'rxjs/operators';
declare const $: any;

@Component({
  selector: 'app-products-section',
  templateUrl: './products-section.component.html',
  styleUrls: ['./products-section.component.css']
})
export class ProductsSectionComponent implements OnInit, OnDestroy{

  subscriptions: Subscription[] = [];
  products$: Observable<Product[]>;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private productEntityService: ProductEntityService
  ) {
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.productEntityService
        .getAll()
        .subscribe()
    )
    this.products$ = this.productEntityService.entities$
      .pipe(
        map(items => items.filter(item => item.level === 1))
      )
  }


  ngOnDestroy() {
    for (let subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

}
