import {Component, OnDestroy, OnInit} from '@angular/core';
import {PortfolioEntityService} from '../store/portfolio-entity.service';
import {Observable, Subscription} from 'rxjs';
import {Portfolio} from '../portfolio.model';
import {filter, map} from 'rxjs/operators';

@Component({
  selector: 'app-portfolio-section',
  templateUrl: './portfolio-section.component.html',
  styleUrls: ['./portfolio-section.component.css']
})
export class PortfolioSectionComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];
  portfolios$: Observable<Portfolio[]>;

  constructor(
    private portfolioEntityService: PortfolioEntityService
  ) { }

  ngOnInit(): void {
    this.subscriptions.push(
      this.portfolioEntityService.getAll().subscribe()
    )
    this.portfolios$ = this.portfolioEntityService.entities$
      .pipe(
        filter(items => items.length > 0),
        map(items => {
          console.log(items)
          let highLightItems = items.filter(item => item.highLight);

          if(highLightItems.length > 4) {
            highLightItems = PortfolioSectionComponent.getRandom(highLightItems, 4);
          }
          else {
            const normalItemsFill = PortfolioSectionComponent.getRandom(items, 4 - highLightItems.length);
            highLightItems.push(...normalItemsFill);
          }

          return highLightItems;
        })
      )
  }

  ngOnDestroy() {
    for (let subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  private static getRandom(arr, n) {
    var result = new Array(n),
      len = arr.length,
      taken = new Array(len);
    if (n > len)
      throw new RangeError("getRandom: more elements taken than available");
    while (n--) {
      var x = Math.floor(Math.random() * len);
      result[n] = arr[x in taken ? taken[x] : x];
      taken[x] = --len in taken ? taken[len] : len;
    }
    return result;
  }

}
