import { NgModule } from '@angular/core';
import {CommonModule} from '@angular/common';
import {EntityDataService, EntityDefinitionService, EntityMetadataMap} from '@ngrx/data';
import {ProductDataService} from './store/product-data.service';
import {ProductEntityService} from './store/product-entity.service';
import {Product, selectProductId} from './our-product.model';
import {ProductsSectionComponent} from './section-page/products-section.component';
import {RouterModule} from '@angular/router';
import { ProductModelComponent } from './pages/product-model/product-model.component';
import {FlexModule} from '@angular/flex-layout';
import {MiscModule} from '../../misc/misc.module';


const entityMetaData: EntityMetadataMap = {
  //Must be the same name of the Entity Model
  Product: {
    selectId: selectProductId
  },
}

@NgModule({
  declarations: [ProductsSectionComponent, ProductModelComponent],
    exports: [ProductsSectionComponent, ProductModelComponent],
  imports: [
    CommonModule,
    RouterModule,
    FlexModule,
    MiscModule,
  ],
  providers:[
    ProductEntityService,
    ProductDataService
  ]
})
export class OurProductsModule {

  constructor(eds: EntityDefinitionService,
              entityDataService: EntityDataService,
              productDataService: ProductDataService,
  )
  {

    eds.registerMetadataMap(entityMetaData);
    entityDataService.registerService('Product', productDataService);
  }

}
