<!-- ======= Our product Section ======= -->
<section id="product" class="product section-bg">
    <div class="container">

      <div class="section-title">
        <h2>Os Nossos Produtos</h2>
      </div>

      <div class="row">
        <ng-container *ngIf="products$ | async as products">
            <div *ngFor="let product of products; let i = index"  class="col-lg-3 col-md-4 product-item" data-aos="fade-up" [attr.data-aos-delay]="100">
              <div class="product-wrap" [routerLink]="'/product-browser'" [queryParams]="[product.id]">
                <img #img [src]="'assets/' + product.assets[0]" (error)="img.src='assets/img/missing/missing_800x600.png'" class="img-fluid" alt="">
                <div class="product-info">
                  <h4>{{product.name}}</h4>
                  <p style="min-height: 21px">
                    <span *ngIf="product.description !== 'x'" style="min-height: 21px" >{{product.description}}</span>
                  </p>
                </div>
              </div>
            </div>
        </ng-container>

      </div>

    </div>
  </section><!-- End Our product Section -->
