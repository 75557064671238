import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule} from '@angular/router';
import {FlexModule} from '@angular/flex-layout';
import {MainContainerComponent} from './main-container.component';
import {TopBarModule} from '../../modules/top-bar/top-bar.module';
import {FooterModule} from '../../modules/footer/footer.module';
import {HeadersModule} from '../../modules/headers/headers.module';

@NgModule({
  declarations: [MainContainerComponent],
  exports: [
    MainContainerComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FlexModule,
    TopBarModule,
    FooterModule,
    HeadersModule
  ]
})
export class MainContainerModule {


}
