<footer id="footer">
  <div class="footer-top">
    <div class="container">
<!--      <div class="row">-->

        <div class="footer-info">
          <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutAlign.lt-sm="space-between center"  fxLayoutGap="0" fxLayoutGap.lt-sm="4rem"  >
            <div fxLayout="column" fxLayoutAlign.lt-sm="space-around center" fxLayoutAlign="space-around start" >
              <h3>Malvar Controls, Lda.</h3>
              <div>
                Rua da Boavista, n.º 36 - 1.º Andar <br>
                Boavista <br>
                4770-754 Vermoim VNF <br>
                Portugal
              </div>
              <div fxFlex class="social-links mt-3" fxLayout="start start">
                <a href="https://pt-pt.facebook.com/malvarcontrols/#" target="_blank" class="facebook"><i class="bx bxl-facebook"></i></a>
                <a href="https://www.linkedin.com/company/malvar-controls/#" target="_blank" class="linkedin"><i class="bx bxl-linkedin"></i></a>
                <!--            <a href="#" class="twitter"><i class="bx bxl-twitter"></i></a>-->
              </div>
            </div>

            <div fxLayout="column" fxLayoutAlign.lt-sm="space-around center" fxLayoutAlign="space-around start" class="footer-links">
              <h4 >Avisos Legais</h4>
              <ul >
                <li><i class="bx bx-chevron-right"></i> <a class="venobox" data-vbtype="inline" title="Política de privacidade" href="#privacy-policy" >Política de Privacidade</a>
                <li><i class="bx bx-chevron-right"></i> <a class="venobox" data-vbtype="inline" title="Política de cookies" href="#cookie-policy" >Política de Cookies</a></li>
                <li><i class="bx bx-chevron-right"></i> <a class="venobox" data-vbtype="inline" title="Política de recrutamento" href="#recruitment-policy" >Política de Recrutamento</a></li>
                <li><i class="bx bx-chevron-right"></i> <a class="venobox" data-vbtype="inline" title="RGPD" href="#rgpd" >RGPD</a></li>
              </ul>
            </div>


          </div>
        </div>
    </div>
  </div>
</footer><!-- End Footer -->
<app-privacy-policy [hidden]="true" id="privacy-policy"></app-privacy-policy>
<app-cookie-policy [hidden]="true" id="cookie-policy"></app-cookie-policy>
<app-recruitment-policy [hidden]="true" id="recruitment-policy"></app-recruitment-policy>
<app-rgpd [hidden]="true" id="rgpd"></app-rgpd>
