import {Injectable} from '@angular/core';
import {EntityCollectionServiceBase, EntityCollectionServiceElementsFactory} from '@ngrx/data';
import {Newsletter} from '../newsletters.model';
import {NewslettersDataService} from './newsletters-data.service';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable()
export class NewslettersEntityService extends EntityCollectionServiceBase<Newsletter> {


  private _years$: BehaviorSubject<number[]> = new BehaviorSubject<number[]>(undefined);
  private _newestNewsletter$: BehaviorSubject<Newsletter> = new BehaviorSubject<Newsletter>(undefined);

  constructor(private serviceElementsFactory: EntityCollectionServiceElementsFactory,
              private newsLettersDataService: NewslettersDataService) {
    super('Newsletter', serviceElementsFactory);
    this.entities$.subscribe(newsletters => {
      this._years$.next(this.calculateYears(newsletters));
      this._newestNewsletter$.next(newsletters[0]);
    })
  }

  private calculateYears(newsletters): number[] {
    const result: number[] = [];
    for (let newsletter of newsletters) {
      const year: number = new Date(newsletter.date).getFullYear();
      if (!result.some(item => item === year)) {
        result.push(year);
      }
    }
    return result.sort((item1, item2) => item1 - item2);
  }

  get years$(): Observable<number[]> {
    return this._years$.asObservable();
  }

  get newestNewsletter$(): Observable<Newsletter> {
    return this._newestNewsletter$.asObservable();
  }

  get favourites$(): Observable<Newsletter[]>{
    return this.newsLettersDataService.getFavourite();
  }

  getYears(): Observable<number[]> {
    return this.newsLettersDataService.getYears();
  }

  getWithYear(year: number): Observable<Newsletter[]> {
    return this.newsLettersDataService.getWithYear(year);
  }
}
