<div class="container" style="padding: 3rem">
  <div class="row first">
    <div class="col-lg-12">
      <h2 class="page-header">Cookies</h2>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <p align="justify">Um <i>cookie</i> é um pequeno ficheiro de texto que é gravado no seu computador ou dispositivo móvel, para ser recuperado posteriormente, conforme necessário, durante as visitas subsequentes ao <i>site</i>. Quando usa os nossos serviços, assumimos automaticamente que você aprova o uso de <i>cookies</i>.</p>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <ul class="list-unstyled project-details">
        <li>
          <strong>Como usamos os <i>cookies</i>?</strong>
          <br>Usamos <i>cookies</i> permanentes para guardar a sua opção de página inicial e guardar os seus detalhes, caso selecione “Lembrar-me” quando iniciar sessão. Usamos <i>cookies</i> de sessão, por exemplo, quando usa a função de filtro de produtos e para verificar se tem sessão iniciada. Usamos <i>cookies</i> do nosso <i>website</i> e de terceiros para recolher estatísticas e dados de utilizador, de forma agregada e individual, em ferramentas de análise, para otimizar o nosso <i>site</i>, além de mostrar material de <i>marketing</i> relevante.
          <br>Alguns <i>cookies</i> de terceiros são configurados por serviços exibidos no nosso site, mas que estão fora do nosso controlo. São configurados por fornecedores de redes sociais, como o Twitter, o Facebook e o Vimeo, e permitem aos utilizadores partilhar conteúdos nestes sites, conforme indicado pelos respetivos ícones.
          <br>Também utilizamos <i>cookies</i> de terceiros que realizam o rastreamento entre sites, para que possamos oferecer materiais de <i>marketing</i> noutros <i>sites</i> e/ou canais.
        </li>
        <li>
          <br><strong>Quem tem acesso aos seus dados pessoais?</strong>
          <br>Os dados disponibilizados a terceiros são usados apenas para fornecer os serviços mencionados acima, ou para fornecer informações para ferramentas de análise, ou para otimizar o nosso <i>site</i> de forma a apresentar-lhe material relevante.
        </li>
        <li>
          <br><strong>Por quanto tempo salvamos seus dados pessoais?</strong>
          <br>A <i>Malvar Controls</i> não guarda os seus dados pessoais. Pode facilmente apagar os cookies do seu computador ou dispositivo móvel utilizando o seu <i>browser</i>. Para obter instruções sobre como gerir e eliminar <i>cookies</i>, selecione a opção "Ajuda" no seu navegador de <i>web</i>. Pode optar por desativar os <i>cookies</i> ou por receber uma notificação sempre que um novo <i>cookie</i> seja enviado para o seu computador ou dispositivo móvel. Tenha em atenção que, se optar por desativar os <i>cookies</i>, deixará de poder utilizar todas as funções do nosso <i>site</i>.
        </li>
      </ul>
      <p>30-05-2018</p>
    </div>
  </div>
</div>
